import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { ProfileProvider } from "./context/ProfileContext";
import { ActiveStepProvider } from "./container/ActiveStepContext";
import { WalletProvider } from "./context/WalletContext";
import ErrorBoundary from "./layouts/ErrorBoundary/ErrorBoundary";
import theme from "./theme/Theme";
import { environment } from "./configs/ApiConfig";

const AppHOC = ({ children }) => {
  useEffect(() => {
    const loadFreshChatScript = () => {
      const scriptId = "Freshchat-js-sdk";
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.async = true;
        script.src = "https://endl.freshchat.com/js/widget.js";
        script.onload = () => {
          console.log("scripts are loaded")
        };
        script.onerror = () => {
          console.error("Failed to load FreshChat script.");
        };
        document.head.appendChild(script);
      }
    };

    loadFreshChatScript();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-right" containerStyle={{ zIndex: 9999999 }} />
        <ProfileProvider>
          <ActiveStepProvider>
            <WalletProvider>
              <ErrorBoundary>{children}</ErrorBoundary>
            </WalletProvider>
          </ActiveStepProvider>
        </ProfileProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

// Suppress console logs in production/demo environments
if (["prod", "demo"].includes(environment)) {
  if (!window.console) window.console = {};
  const methods = ["log", "debug", "info", "table", "error", "warn"];
  methods.forEach((method) => {
    console[method] = function () {};
  });
}

export default AppHOC;
