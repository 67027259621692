import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Modal,
  Popover,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { notify } from "../../../../components/Notification/Notification";
import PdfViewer from "./PdfViewer";
import { AttachFile } from "@mui/icons-material";
import commonHelpers from "../../../../utils/common.helpers";

const ManualPaymentReviewDetails = ({
  sendAmount,
  sourceCurrency,
  receiveAmount,
  receiveCurrency,
  selectedRecipient,
  chargeTotal,
  handleGoBack,
  submitMaunalTranscation,
  depositAddress,
  qrCodeImage,
  handleManualPayment,
  chargesFee,
  totalFeesCurrency,
  attachedFile = {},
  isQuoteExpired,
  expiresOn,
  exchangeRate,
  selectedPaymentType,
  currentSelectedFile={},
  setCurrentSelectedFile,
  amountToConvert,
  networkValue,
}) => {
  const [timeLeft, setTimeLeft] = useState(600);
  const [notified, setNotified] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [open, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (expiresOn) {
      const expirationDate = new Date(expiresOn);
      const totalDuration = expirationDate - new Date();

      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = expirationDate - now;
        if (timeDiff <= 0) {
          setRemainingTime("Expired");
          isQuoteExpired = true;
        } else {
          const minutes = Math.floor(timeDiff / 1000 / 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setRemainingTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        }
      };

      updateRemainingTime();
      const timerInterval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [expiresOn, isQuoteExpired]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          handleGoBack();
          return 0;
        }

        if (prevTime <= 2 && !notified) {
          notify.success({
            message: "Time is almost up! Please reinstate the payment.",
          });
          setNotified(true);
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [handleGoBack]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        notify.success({
          message: `Copied ${text}`,
        });
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const toggleIsOpen = () => {
    setIsOpen(!open);
  };

  const handleSelectFile = (file) => {
    setCurrentSelectedFile(file)
    toggleIsOpen();
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  console.log("selectedRecipient" , selectedRecipient)

  return (
    <Grid container p={{ xs: 3, md: 5 }} justifyContent={{ xs: "center", lg: "flex-start" }}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} width={{ xs: "100%", sm: "50%", lg: "60%" }} gap={2} flexDirection={"column"}>
            <Typography variant="h5" fontWeight={600}>
              Review & Pay
            </Typography>
            <Typography fontSize={14} color="error.main">
              Your quotation expires in <span fontWeight={'bold'}>{remainingTime}</span>
            </Typography>
            {/* <Typography fontSize={14} textAlign={{ xs: "end", md: "start" }}>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {remainingTime}{" "}
              </span>{" "}
              remaining
            </Typography> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
          <Typography fontWeight={600} textAlign={'center'}>
            Scan QR code
          </Typography>
          <Box display={"flex"} gap={2} flexDirection={"column"} alignItems={"center"}>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={200}
              height={200}
            >
              {qrCodeImage ? (
                <img
                  width={"100%"}
                  height={"100%"}
                  src={qrCodeImage}
                  alt="QR Code"
                />
              ) : (
                <Typography>Loading...</Typography>
              )}
            </Box>
            <Typography fontSize={14}>Scan this QR code using your wallet app.</Typography>
          </Box>
          <Divider />
          <Typography fontSize={14} fontWeight={600}>
            Or copy details and pay with any wallet
          </Typography>
          <Box>
            <Typography fontSize={14}>Send amount</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography>
                {(sendAmount)?.toLocaleString()} {sourceCurrency}
              </Typography>
              <IconButton
                onClick={() =>
                  copyToClipboard(`${sendAmount} ${sourceCurrency}`)
                }
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography fontSize={14}>Send to address</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography width={{ xs: "90%", md: "100%" }} overflow={{ xs: "hidden" }}>{depositAddress}</Typography>
              <IconButton onClick={() => copyToClipboard(depositAddress)}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          {/* Total fee with Tooltip for detailed fee breakdown */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color={"rgba(0,0,0,0.60)"} fontWeight={'bold'} fontFamily="PPMori-Regular">
                {commonHelpers.capitalizeFirstLetter("Total fees")}
              </Typography>
              {chargesFee?.length ? (
                <Tooltip
                  title={
                    <Box>
                      {chargesFee?.map((fee, index) => (
                        <Box key={index} display="flex" justifyContent="space-between" gap={1}>
                          <Typography fontSize={"0.75rem"} fontFamily="PPMori-Regular">{fee.feeName}</Typography>
                          <Typography fontSize={"0.75rem"} fontFamily="PPMori-Regular">{fee.feeAmount} {fee.feeCurrency}</Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                  arrow
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              ) : null}
            </Box>
            <Typography fontFamily="PPMori-Regular">{chargeTotal} {totalFeesCurrency}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography color={"rgba(0,0,0,0.60)"} fontFamily="PPMori-Regular">
              {commonHelpers.capitalizeFirstLetter("Exchange rate")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "PPMori-Regular",
              }}
            >

              {exchangeRate ? (<>
                1 {sourceCurrency} = {exchangeRate} {receiveCurrency}</>) : 0}

            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography color={"rgba(0,0,0,0.60)"} fontFamily="PPMori-Regular">
              {commonHelpers.capitalizeFirstLetter("Total Amount we'll Convert")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "PPMori-Regular",
              }}
            >
              {Number(amountToConvert)?.toLocaleString()} {sourceCurrency}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"rgba(0,0,0,0.60)"} fontFamily="PPMori-Regular">
              {commonHelpers.capitalizeFirstLetter("Recipient gets")}
            </Typography>
            <Typography fontWeight={600} fontSize={22}>
              {(receiveAmount)?.toLocaleString()} {receiveCurrency}
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              Recipient
            </Typography>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
            >
              {selectedRecipient?.name}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              {commonHelpers.capitalizeFirstLetter("To bank account")}
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
            >
              {selectedRecipient?.account_number_external ?
                `${selectedRecipient?.name}...${selectedRecipient?.account_number_external?.slice(-4) || selectedRecipient?.iban?.slice(-4)}` :
                `${selectedRecipient?.bankName}...${selectedRecipient?.bankAccountNumber?.slice(-4) || selectedRecipient?.iban?.slice(-4)}`
              }
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              {commonHelpers.capitalizeFirstLetter("Payment method")}
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
            >
              {commonHelpers.capitalizeFirstLetter("QR Code payment")}
            </Typography>
          </Box>
          {selectedPaymentType === "cryptoToFiat" && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              {commonHelpers.capitalizeFirstLetter("Payment rail")}
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
            >
             {selectedRecipient?.paymentRail}
            </Typography>
          </Box>)}
          {(selectedPaymentType === "cryptoToFiat" || selectedPaymentType === "cryptoCurrency") && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              {commonHelpers.capitalizeFirstLetter("Blockchain network")}
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
            >
             {networkValue}
            </Typography>
          </Box>)}
          <Divider />
          {/* Attached Document */}
          {selectedPaymentType === "cryptoToFiat" && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            mb={2}
          >
            <Typography color="text.secondary">Attached document</Typography>
            {attachedFile?.length > 0 ? (
              <Box display="flex" gap={1}>
                <Button
                  startIcon={attachedFile[0]?.name && <AttachFile />}
                  variant="text"
                  onClick={() => handleSelectFile(attachedFile[0])}
                  sx={{
                    textDecoration: attachedFile[0]?.name
                      ? "underline"
                      : "none",
                    textTransform: "capitalize",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      maxWidth: "250px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                      fontSize: "14px",
                    }}
                  >
                    {attachedFile[0]?.name?.length > 20
                      ? `${attachedFile[0]?.name?.slice(0, 20)}...`
                      : attachedFile[0]?.name}
                  </Typography>
                </Button>
                {attachedFile?.length > 1 && (
                  <>
                    <Button
                      variant="text"
                      onClick={handlePopoverOpen}
                      sx={{
                        textDecoration: "none",
                        textTransform: "capitalize",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          alignSelf: "center",
                          cursor: "pointer",
                          color: "#1976d2",
                        }}
                      >
                        +{attachedFile.length - 1} more
                      </Typography>
                    </Button>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box p={2}>
                        {attachedFile.slice(1).map((file, index) => (
                          <Button
                            key={index}
                            variant="text"
                            onClick={() => handleSelectFile(file)}
                            sx={{
                              display: "block",
                              textAlign: "left",
                              textTransform: "none",
                              minWidth: "200px",
                            }}
                          >
                            <Typography variant="body2">{file.name}</Typography>
                          </Button>
                        ))}
                      </Box>
                    </Popover>
                  </>
                )}
              </Box>
            ) : (
              <Typography variant="body1" fontFamily="PPMori-Regular">
                N/A
              </Typography>
            )}
          </Box>
        )}

          <Typography>Disclaimer: The recipient bank may charge additional fees, which are not included in the estimated amount.</Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              backgroundColor: "#462A9C",
              ":hover": {
                backgroundColor: "#462A9C",
              },
            }}
            onClick={handleManualPayment}
          >
            Finish, I've paid
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "none",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              mt: 1,
            }}
            onClick={handleGoBack}
          >
            Go back
          </Button>
        </Box>
        {/* Modal for attached document */}
        <Modal open={open} onClose={toggleIsOpen}>
          <Box
            width={"80%"}
            height={"80%"}
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            borderRadius={3}
            boxShadow={5}
            mx="auto"
            my="15vh"
            p={2}
            outline="none"
          >
            <PdfViewer file={currentSelectedFile} />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default ManualPaymentReviewDetails;
