import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../lib/service/AuthToken";
import Login from "../../pages/LoginAndSignUp/Login";
import { login } from "../../lib/service/authApi/authApi";
import { notify } from "../../components/Notification/Notification";
import { getProfile, getProfilePicture } from "../../lib/service/ProfileApi/profileApi";
import ProfileContext from "../../context/ProfileContext";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/slices/userSlice";

const LoginContainer = () => {
  const { setProfile, setFormsData, setFullName, setPreviewUrl, setLoading } = useContext(ProfileContext);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({ email: null, password: null });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidInput = () => {
    let isValid = true;
    const errors = { ...formErrors };
    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isValidInput()) return;
  
    setIsLoading(true);
    try {
      const response = await login(formData);
      if (response.otpRequiredForLogin) {
        navigate("/2fa-login");
        return;
      }
  
      const authToken = response.data?.token || "";
      const userId = response.data?.userId || "";
      if (authToken) {
        setAuthToken(authToken, userId);
        dispatch(userActions.setIsAuthenticated(true));
        notify.success({ message: "You are successfully logged in." });
  
        // Reset profile state here
        dispatch(userActions.setProfileData(null)); // Reset previous profile data
  
        await fetchUserData(authToken);
      } else {
        notify.error({ message: "Failed to login", description: "Invalid credentials." });
      }
    } catch (error) {
      handleLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleLoginError = (error) => {
    console.error("Error during login:", error);
    if (error?.errors?.length) {
      error.errors.forEach(err => notify.error({ message: err.errMsg, description: err.errDesc }));
    } else {
      notify.error({ message: "Failed to login", description: "Invalid credentials." });
    }
  };

  const fetchUserData = async (authToken) => {
    try {
      await Promise.all([fetchProfile(authToken), fetchProfilePicture(authToken)]);
      navigate("/");
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const fetchProfile = async (authToken) => {
    try {
      const response = await getProfile(authToken);
      if (response.status === 'Success') {
        const userProfile = response.data;
        dispatch(userActions.setProfileData({
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          dateOfBirth: dayjs(userProfile.dateOfBirth),
          phone: userProfile.phone,
          email: userProfile.email,
          addressLine1: userProfile.addressLine1,
          addressLine2: userProfile.addressLine2,
          city: userProfile.city,
          state: userProfile.state,
          country: userProfile.country,
          postalCode: userProfile.postalCode,
        }));
        setProfile(userProfile);
        setFormsData(userProfile);
        setFullName(`${userProfile.firstName} ${userProfile.lastName}`);
      }
    } catch (error) {
      console.error('Failed to fetch profile data', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProfilePicture = async (authToken) => {
    try {
      const profileResponse = await getProfilePicture(authToken);
      if (profileResponse instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          dispatch(userActions.setProfileData({ profileUrl: reader.result }))
          setPreviewUrl(reader.result); // Force refresh
        };
        reader.readAsDataURL(profileResponse);
      } else {
        console.warn("Failed to fetch profile picture", profileResponse);
        setPreviewUrl(null);
      }
    } catch (error) {
      console.error("Failed to fetch profile picture", error);
      setPreviewUrl(null); // Reset in case of error
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: null }));
  };

  return (
    <Login
      formData={formData}
      handleInputChange={handleInputChange}
      formErrors={formErrors}
      onSubmit={handleLogin}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
