import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TermsOfUseModal from "../../pages/TermsOfUseModule/TermsContent";
import PrivacyModal from "../../pages/PrivacyModule/PrivacyContent";
import CustomerAgreement from "../../pages/CustomerAgreement/customerAgreement";

const AgreementModal = ({ open = false, onClose = () => {}, setIsCertified = () => {} }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [scrolledTabs, setScrolledTabs] = useState({
    customerAgreement: false,
    termsOfUse: false,
    privacyPolicy: false,
  });

  // Separate refs for each tab's scrollable content
  const customerAgreementRef = useRef(null);
  const termsOfUseRef = useRef(null);
  const privacyPolicyRef = useRef(null);

  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Detect end of scroll for each tab
  const handleScrollEnd = (ref, tabName) => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setScrolledTabs((prev) => ({ ...prev, [tabName]: true }));
      }
    }
  };

  // Check if all tabs are scrolled to enable the checkbox
  const isCertified = Object.values(scrolledTabs).every(Boolean);
  useEffect(() => {
    setIsCertified(isCertified);
  }, [isCertified, setIsCertified]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            width: "90vw",
            height: "90vh",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 24,
            display: "flex",
            flexDirection: "row",
            position: "relative",
            padding: 4,
            outline: "none", 
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#6B7280",
              zIndex: 1000,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Tabs Section */}
          <Box
            sx={{
              width: "200px",
              paddingRight: 3,
              borderRight: "1px solid #E0E0E0",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              orientation="vertical"
              sx={{
                "& .MuiTab-root": {
                  padding: "10px 15px",
                  color: "#6B7280",
                  fontSize: "14px",
                  fontWeight: "400",
                  border: "1px solid transparent",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  backgroundColor: "#F8F8F8",
                  transition: "background-color 0.3s, color 0.3s",
                },
                "& .Mui-selected": {
                  color: "#000",
                  backgroundColor: "#EFEFEF",
                  border: "1px solid #27009C",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label="Customer Agreement" />
              <Tab label="Terms & Conditions" />
              <Tab label="Privacy Policy" />
            </Tabs>
          </Box>

          {/* Tab Content Section */}
          <Box
            sx={{
              flex: 1,
              paddingLeft: 3,
              paddingRight: 3,
              overflowY: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              position: "relative",
              height: "100%",
            }}
          >
            {/* Customer Agreement Content */}
            {activeTab === 0 && (
              <Box
                ref={customerAgreementRef}
                onScroll={() => handleScrollEnd(customerAgreementRef, "customerAgreement")}
                sx={{ height: "100%", overflowY: "auto", padding: 2 }}
              >
                <CustomerAgreement />
              </Box>
            )}

            {/* Terms of Use Content */}
            {activeTab === 1 && (
              <Box
                ref={termsOfUseRef}
                onScroll={() => handleScrollEnd(termsOfUseRef, "termsOfUse")}
                sx={{ height: "100%", overflowY: "auto", padding: 2 }}
              >
                <TermsOfUseModal />
              </Box>
            )}

            {/* Privacy Policy Content */}
            {activeTab === 2 && (
              <Box
                ref={privacyPolicyRef}
                onScroll={() => handleScrollEnd(privacyPolicyRef, "privacyPolicy")}
                sx={{ height: "100%", overflowY: "auto", padding: 2 }}
              >
                <PrivacyModal />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AgreementModal;
