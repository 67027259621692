import { Box, Divider, Typography, Button, useMediaQuery, LinearProgress, Modal, Popover } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AttachFile } from "@mui/icons-material";
import PdfViewer from "../CryptoToFiatFlow/PdfViewer";
import commonHelpers from "../../../../utils/common.helpers";

const FiatReviewDetails = ({
    sendAmount,
    chargesFee,
    walletAddress,
    sourceCurrency,
    handleGoBack,
    handleSubmit,
    handleNext,
    isFetching,
    receiveAmount,
    receiveCurrency,
    selectedPaymentType,
    selectedRecipient,
    chargeTotal,
    totalFeesCurrency,
    isQuoteExpired,
    expiresOn,
    attachedFile = {},
    exchangeRate,
    currentSelectedFile = {},
    setCurrentSelectedFile,
    amountToConvert,
}) => {
    console.log(amountToConvert, 'Recipient get')
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [remainingTime, setRemainingTime] = useState("");
    const [open, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const toggleIsOpen = () => {
        setIsOpen(!open);
    };

    useEffect(() => {
        if (expiresOn) {
            const expirationDate = new Date(expiresOn);
            const totalDuration = expirationDate - new Date();

            const updateRemainingTime = () => {
                const now = new Date();
                const timeDiff = expirationDate - now;
                if (timeDiff <= 0) {
                    setRemainingTime("Expired");
                    isQuoteExpired = true;
                } else {
                    const minutes = Math.floor(timeDiff / 1000 / 60);
                    const seconds = Math.floor((timeDiff / 1000) % 60);
                    setRemainingTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
                }
            };

            updateRemainingTime(); // Initial call
            const timerInterval = setInterval(updateRemainingTime, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [expiresOn, isQuoteExpired]);

    const handleSelectFile = (file) => {
        setCurrentSelectedFile(file)
        toggleIsOpen();
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display={"flex"} justifyContent={"center"} padding={{ xs: 2, sm: 5 }}>
            <Box display={"flex"} flexDirection={"column"} width={{ xs: '90%', sm: "60%", md: "40%" }} gap={1}>
                {isQuoteExpired && (
                    <Typography
                        color="error"
                        mt={2}
                        fontWeight="bold"
                        textAlign="center"
                        fontSize={16}
                    >
                        Quote expired! Please generate a new one.
                    </Typography>
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography fontWeight={600} fontSize={24} display="flex" alignItems="center">
                        Summary
                    </Typography>
                    {remainingTime && !isQuoteExpired && (
                        <Box
                            px={1}
                            py={0.5}
                            display="flex"
                            alignItems="center"
                            color="error.main"
                            fontSize={14}
                            fontWeight={500}
                        >
                            Quote expires in: {remainingTime}
                        </Box>
                    )}
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems={"center"}
                >
                    <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
                        Total amount {isMobile && <br />} to send
                    </Typography>
                    <Typography fontFamily="PPMori-Regular" fontWeight={400}>
                        {(sendAmount)?.toLocaleString()} {sourceCurrency}
                    </Typography>
                </Box>
                {chargesFee?.length && chargesFee.map((charges, index) => (
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                            variant="body1"
                            fontFamily="PPMori-Regular"
                            color={"rgba(0,0,0,0.60)"}
                        >
                            {commonHelpers.capitalizeFirstLetter(charges.feeName)}
                        </Typography>
                        <Typography variant="body1" fontFamily="PPMori-Regular">
                            {charges.feeAmount} {charges.feeCurrency}
                        </Typography>
                    </Box>
                ))}

                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}>
                    <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
                        {commonHelpers.capitalizeFirstLetter("Total fee amount")}
                    </Typography>
                    <Typography fontFamily="PPMori-Regular" fontWeight={400}>
                        {chargeTotal} {totalFeesCurrency}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Typography color="text.secondary">
                        {commonHelpers.capitalizeFirstLetter("Exchange rate")}
                    </Typography>
                    <Typography
                        fontFamily="PPMori-Regular" fontWeight={400}
                    >

                        {exchangeRate ? (<>
                            1 {sourceCurrency} = {exchangeRate} {receiveCurrency}</>) : 0}

                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
                        Total amount {isMobile && <br />} we'll convert
                    </Typography>
                    <Typography fontFamily="PPMori-Regular" fontWeight={400}>
                        {(amountToConvert)?.toLocaleString()} {sourceCurrency}
                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
                        {commonHelpers.capitalizeFirstLetter("Recipient get")}
                    </Typography>
                    <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
                        {(receiveAmount)?.toLocaleString()} {receiveCurrency}
                    </Typography>
                </Box>

                <Divider />
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                        variant="body1"
                        fontFamily="PPMori-Regular"
                        color={"rgba(0,0,0,0.60)"}
                    >
                        Recipient
                    </Typography>
                    <Typography variant="body1" fontFamily="PPMori-Regular">
                        {selectedPaymentType === "cryptoToFiat" ? selectedRecipient?.name ? selectedRecipient?.name : selectedRecipient.firstName + "  " + selectedRecipient.lastName : selectedRecipient?.name}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
                        {commonHelpers.capitalizeFirstLetter("Account Number/IBAN")}
                    </Typography>
                    <Typography fontFamily="PPMori-Regular" variant="body1" width={"50%"} textAlign="right">
                        {
                            selectedRecipient
                                ? `${commonHelpers.capitalizeFirstLetter(selectedRecipient.bankName)}...${selectedRecipient?.bankAccountNumber?.slice(-4) || selectedRecipient?.iban?.slice(-4)}`
                                : null
                        }
                    </Typography>
                </Box>
                <Divider />
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                        variant="body1"
                        fontFamily="PPMori-Regular"
                        color={"rgba(0,0,0,0.60)"}
                    >
                        {commonHelpers.capitalizeFirstLetter("Payment method")}
                    </Typography>
                    <Typography variant="body1" fontFamily="PPMori-Regular">
                        Endl Account
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                       variant="body1"
                       fontFamily="PPMori-Regular"
                       color={"rgba(0,0,0,0.60)"}
                    >
                        {commonHelpers.capitalizeFirstLetter("Payment rail")}
                    </Typography>
                    <Typography
                        fontFamily="PPMori-Regular"
                        variant="body1"
                    >
                        {selectedRecipient?.paymentRail}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                        variant="body1"
                        fontFamily="PPMori-Regular"
                        color={"rgba(0,0,0,0.60)"}
                    >
                        {commonHelpers.capitalizeFirstLetter("Source currency")}
                    </Typography>
                    <Typography variant="body1" fontFamily="PPMori-Regular">
                        {sourceCurrency}
                    </Typography>
                </Box>
                {selectedPaymentType !== 'cryptoCurrency' && (
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems="center"
                        mb={2}
                    >
                        <Typography color="text.secondary">{commonHelpers.capitalizeFirstLetter("Attached document")}</Typography>
                        {attachedFile?.length > 0 ? (
                            <Box display="flex" gap={1}>
                                <Button
                                    startIcon={attachedFile[0]?.name && <AttachFile />}
                                    variant="text"
                                    onClick={() => handleSelectFile(attachedFile[0])}
                                    sx={{
                                        textDecoration: attachedFile[0]?.name
                                            ? "underline"
                                            : "none",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            maxWidth: "250px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "inline-block",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {attachedFile[0]?.name?.length > 20
                                            ? `${attachedFile[0]?.name?.slice(0, 20)}...`
                                            : attachedFile[0]?.name}
                                    </Typography>
                                </Button>
                                {attachedFile?.length > 1 && (
                                    <>
                                        <Button
                                            variant="text"
                                            onClick={handlePopoverOpen}
                                            sx={{
                                                textDecoration: "none",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    alignSelf: "center",
                                                    cursor: "pointer",
                                                    color: "#1976d2",
                                                }}
                                            >
                                                +{attachedFile?.length - 1} more
                                            </Typography>
                                        </Button>
                                        <Popover
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={handlePopoverClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                        >
                                            <Box p={2}>
                                                {attachedFile.slice(1).map((file, index) => (
                                                    <Button
                                                        key={index}
                                                        variant="text"
                                                        onClick={() => handleSelectFile(file)}
                                                        sx={{
                                                            display: "block",
                                                            textAlign: "left",
                                                            textTransform: "none",
                                                            minWidth: "200px",
                                                        }}
                                                    >
                                                        <Typography variant="body2">{file.name}</Typography>
                                                    </Button>
                                                ))}
                                            </Box>
                                        </Popover>
                                    </>
                                )}
                            </Box>
                        ) : (
                            <Typography variant="body1" fontFamily="PPMori-Regular">
                                N/A
                            </Typography>
                        )}
                    </Box>
                )}

                <Typography>Disclaimer: The recipient bank may charge additional fees, which are not included in the estimated amount.</Typography>

                <Box width={"100%"} display={"flex"} gap={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderColor: "#462A9C",
                            color: "#462A9C",
                            fontSize: "12px",
                            fontFamily: "PPMori-Regular",
                            width: "100%",
                            py: 1,
                        }}
                        fullWidth
                        onClick={handleGoBack}
                    >
                        Go back
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                            backgroundColor: "#462A9C",
                            color: "#ffffff",
                            fontSize: "12px",
                            fontFamily: "PPMori-Regular",
                            width: "100%",
                            py: 1,
                        }}
                        onClick={handleNext}
                        disabled={sendAmount === 0 || isFetching || isQuoteExpired}
                    >
                        {" "}
                        Next
                    </Button>
                </Box>
            </Box>
            <Modal open={open} onClose={toggleIsOpen}>
                <Box
                    width={"80%"}
                    height={"80%"}
                    display="flex"
                    flexDirection="column"
                    bgcolor="background.paper"
                    borderRadius={3}
                    boxShadow={5}
                    mx="auto"
                    my="15vh"
                    p={2}
                    outline="none"
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <PdfViewer file={currentSelectedFile} />
                </Box>
            </Modal>
        </Box>
    );
};

export default FiatReviewDetails;