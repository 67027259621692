import { Password } from "@mui/icons-material";
import React, { useState } from "react";
import {
  forgetPasswordOTPEmail,
  resetPassword,
} from "../../lib/service/authApi/authApi";
import { useNavigate } from "react-router-dom";
import ForgetPassword from "../../pages/LoginAndSignUp/ForgetPassword";
import { notify } from "../../components/Notification/Notification";

const ForgetPasswordContainer = () => {
  const [currStep, setCurrStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
    password_again: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
    password_again: null,
  });
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isValidInput = () => {
    const errors = { ...formErrors };
    let isValid = true;
    if (currStep === 1) {
      if (!formData.email || formData.email === "") {
        errors.email = "Email is required";
        isValid = false;
      }
    }
    // if (
    //   !formData.email.match(
    //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
    //   )
    // ) {
    //   errors.email = "Please enter a valid email address";
    //   isValid = false;
    // }
    if (currStep === 2) {
      if (!formData.password || formData.password === "") {
        errors.password = "Password is required";
        isValid = false;
      }
      if (!formData.password_again || formData.password_again === "") {
        errors.password_again = "Confirm password is required";
        isValid = false;
      }
      // if (
      //   formData.password_again !== "" &&
      //   formData.password !== "" &&
      //   formData.password_again !== formData.password
      // ) {
      //   errors.password_again =
      //     "Your new password and confirm password should be same";
      //   isValid = false;
      // }
      if (!formData.otp || formData.otp === "") {
        errors.otp = "OTP is required";
        isValid = false;
      }
    }
    setFormErrors(errors);
    return isValid;
  };
  const handleNext = async (e) => {
    e.preventDefault();
    if (!isValidInput()) return;
    if (currStep === 1) {
      try {
        await handleSendOTP();
        setCurrStep(2);
      } catch (e) {
        console.log(e);
      }
    }
    if (currStep === 2) {
      try {
        setIsLoading(true);
        const res = await resetPassword({
          email: formData.email,
          password: formData.password,
          password_again: formData.password_again,
          otp: formData.otp,
        });
        notify.success({
          message: "Your password has been reset successfully",
        });
        navigate("/login");
      } catch (e) {
        console.log(e);
        if (e?.errors?.length > 0) {
          e.errors.map((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        } else {
          notify.error({
            message: "Failed to reset.",
            description:
              "We can not proceed th request for forgot password now. Please try again later",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
  };
  const handleSendOTP = async () => {
    try {
      setIsSendingOTP(true);
      const res = await forgetPasswordOTPEmail({ email: formData.email, phone: null, otpMode: 'EMAIL', operationType: 'RESET_PASSWORD' });
      notify.success({
        message: "OTP has been sent to your email",
        description: `We have sent the OTP to ${formData.email}.Please check your email`,
      });
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to send the OTP.",
          description:
            "We can not proceed the request for forgot password now. Please try again later",
        });
      }
      throw Error();
    } finally {
      setIsSendingOTP(false);
    }
  };
  const handleGoBack = () => {
    setCurrStep((prev) => prev - 1);
  };
  return (
    <ForgetPassword
      currStep={currStep}
      handleInputChange={handleInput}
      formData={formData}
      formErrors={formErrors}
      handleNext={handleNext}
      isLoading={isLoading}
      isSendingOTP={isSendingOTP}
      resendOTPHandler={handleSendOTP}
      handleGoBack={handleGoBack}
    />
  );
};

export default ForgetPasswordContainer;
