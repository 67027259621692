import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "../../pages/Recipients/Recipients.css";
import { getRecipient } from "../../lib/service/RecipientsApi/RecipientsApi";
import dot from "../../Images/dot.png";
import PopoverList from "../Popover/PopoverList";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RenderSkeletonRow from "../LoaderUI/TableLoader";
import {
  getCombinedAddress,
  getRecipientFullName,
  relationshipToRecipientMap,
  bankRoutingCodeLabel,
} from "../../utils/recipeint.helpers";
import EmptyTableComponent from "./EmptyTableComponent";
import commonHelpers from "../../utils/common.helpers";

const recipientData = [
  "Recipient Name",
  "Recipient Type",
  "Currency",
  "Recipient ID",
];

const tabledata = {
  title: "Add recipient",
  recipient: "No recipients yet",
  description: "You can add a new recipient manually.",
};

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));


const CryptoRecipientDetails = ({ open, id, recipient, setRecipient }) => {
  return (
    <>
      <TableRow style={{ backgroundColor: "#F6F5F9" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} display={"flex"} width={"100%"}>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                borderRight={1}
                borderColor={"rgba(0,0,0,0.2)"}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Account details
                </Typography>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Account type
                  </Typography>
                  <Typography fontSize={12}>Crypto account</Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Display name
                  </Typography>
                  <Typography fontSize={12}>
                    {commonHelpers.capitalizeFirstLetter(`${recipient.firstName}) ${recipient.lastName}`)}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Email
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.email && recipient.email !== ""
                      ? recipient.email
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                px={3}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Payment method details
                </Typography>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Wallet address
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.walletAddress && recipient.walletAddress !== ""
                      ? recipient.walletAddress
                      : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Wallet chain
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.walletChain && recipient.walletChain !== ""
                      ? recipient.walletChain
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const BankRecipientDetails = ({ open, id, recipient, setRecipient }) => {
  return (
    <>
      <TableRow style={{ backgroundColor: "#F6F5F9" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} display={"flex"} width={"100%"}>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                borderRight={1}
                borderColor={"rgba(0,0,0,0.2)"}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Recipient details
                </Typography>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Full name
                  </Typography>
                  <Typography fontSize={12}>
                    {getRecipientFullName(
                      recipient.firstName,
                      (recipient?.lastName || "")
                    )}
                  </Typography>
                </Box>
                {recipient?.alias && <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Alias/Nickname
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.alias && recipient.alias !== ""
                      ? commonHelpers.capitalizeFirstLetter(recipient.alias)
                      : "-"}
                  </Typography>
                </Box>}
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Account type
                  </Typography>
                  <Typography fontSize={12}>Bank account</Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Currency
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.currency && recipient.currency !== ""
                      ? recipient.currency
                      : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Email
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.email && recipient.email !== ""
                      ? recipient.email
                      : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Address
                  </Typography>
                  <Typography fontSize={12}>
                    {getCombinedAddress(recipient.recipientAddress)}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Date of birth
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.dateOfBirth && recipient.dateOfBirth !== ""
                      ? recipient.dateOfBirth
                      : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Relationship to recipient
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient.relationshipToCustomer &&
                    recipient.relationshipToCustomer !== ""
                      ? relationshipToRecipientMap[
                          recipient.relationshipToCustomer
                        ]
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                px={3}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Account details
                </Typography>
                {recipient.bankAccountNumber &&
                  recipient.bankAccountNumber !== "" && (
                    <Box>
                      <Typography fontSize={12} color={"gray"}>
                        Account number
                      </Typography>
                      <Typography fontSize={12}>
                        {recipient.bankAccountNumber}
                      </Typography>
                    </Box>
                  )}
                {recipient.bankRoutingNumber &&
                  recipient.bankRoutingNumber !== "" && (
                    <Box>
                      <Typography fontSize={12} color={"gray"}>
                        Routing number
                      </Typography>
                      <Typography fontSize={12}>
                        {recipient.bankRoutingNumber}
                      </Typography>
                    </Box>
                  )}

                {recipient.iban && recipient.iban !== "" && (
                  <Box>
                    <Typography fontSize={12} color={"gray"}>
                      IBAN
                    </Typography>
                    <Typography fontSize={12}>{recipient.iban}</Typography>
                  </Box>
                )}

                {recipient.swiftBic && recipient.swiftBic !== "" && (
                  <Box>
                    <Typography fontSize={12} color={"gray"}>
                      SWIFT/BIC
                    </Typography>
                    <Typography fontSize={12}>{recipient.swiftBic}</Typography>
                  </Box>
                )}

                {recipient.ifsc && recipient.ifsc !== "" && (
                  <Box>
                    <Typography fontSize={12} color={"gray"}>
                      IFSC
                    </Typography>
                    <Typography fontSize={12}>{recipient.ifsc}</Typography>
                  </Box>
                )}

                {recipient.accountType && recipient.accountType !== "" && (
                  <Box>
                    <Typography fontSize={12} color={"gray"}>
                      Account type
                    </Typography>
                    <Typography fontSize={12}>
                      {recipient.accountType}
                    </Typography>
                  </Box>
                )}

                {recipient.bankName && recipient.bankName !== "" && (
                  <Box>
                    <Typography fontSize={12} color={"gray"}>
                      Bank name
                    </Typography>
                    <Typography fontSize={12}>{commonHelpers.capitalizeFirstLetter(recipient.bankName)}</Typography>
                  </Box>
                )}

                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Payment Rail
                  </Typography>
                  <Typography fontSize={12}>
                    {recipient?.paymentRail || "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Address
                  </Typography>
                  <Typography fontSize={12}>
                    {getCombinedAddress(recipient.institutionalAddress)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Row = React.memo(({ row, onUpdateRecipientClick, onDeleteRecipientClick, countryCode = [] }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const recipientActions = [
    // {
    //   label: "Edit recipient",
    //   onClick: () => {
    //     setAnchorEl(null);
    //     onUpdateRecipientClick(row);
    //   },
    //   icon: <ModeEditOutlineOutlinedIcon />,
    // },
    {
      label: "Delete recipient",
      onClick: () => {
        setAnchorEl(null);
        onDeleteRecipientClick(row);
      },
      icon: (
        <DeleteOutlinedIcon sx={{ color: "#FA3228" }} />
      ),
      style: { color: "#FA3228" },
    },
  ];

  const code = countryCode.find(fd => fd === row.currency?.toLowerCase());
  const imagePath = images[`${row.institutionalAddress?.country?.toLowerCase()}.png`];
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ fontFamily: "PPMori-Regular" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{commonHelpers.capitalizeFirstLetter(`${row?.firstName} ${row?.lastName || ""}`)}</TableCell>
        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left"> {row?.currencySymbol} {row.currency} </TableCell>
        <TableCell component="th" scope="row">{row.id}</TableCell>
        <TableCell align="left">
          <img src={dot} alt="details icon" className="dot" onClick={handleClick} />
          <PopoverList
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            options={recipientActions}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </TableCell>
      </TableRow>

      {row.receiveMode === "CRYPTO_WALLET" ? (
        <CryptoRecipientDetails open={open} id={row.id} recipient={row} />
      ) : (
        <BankRecipientDetails open={open} id={row.id} recipient={row} />
      )}
    </React.Fragment>
  );
});


Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        customerId: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

const RecipientTableComponent = ({
  rows, recipientActions, onUpdateRecipientClick, onDeleteRecipientClick, currencyListData, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage,totalItems
}) => {
  const countryCodes = currencyListData.map(item => item.isCrypto ? item.code?.toLowerCase() : item.country?.toLowerCase());

  return (
    <TableContainer component={Paper} style={{ width: "100%", height: "100%" }}>
      <Table aria-label="collapsible table" style={{ fontFamily: "PPMori-Regular" }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#F6F5F9", fontFamily: "PPMori-Regular" }}>
            <TableCell />
            <TableCell style={{ fontWeight: "bold" }}>Recipient Name</TableCell>
            <TableCell style={{ fontWeight: "bold", borderLeft: "1px solid #DDDAD7" }}>Recipient Type</TableCell>
            <TableCell style={{ fontWeight: "bold", borderLeft: "1px solid #DDDAD7" }}>Currency</TableCell>
            <TableCell style={{ fontWeight: "bold", borderLeft: "1px solid #DDDAD7" }}>Recipient ID</TableCell>
            <TableCell style={{ fontWeight: "bold" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row
              key={row.id}
              row={row}
              recipientActions={recipientActions}
              onUpdateRecipientClick={onUpdateRecipientClick}
              onDeleteRecipientClick={onDeleteRecipientClick}
              countryCode={countryCodes}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};


RecipientTableComponent.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          customerId: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    })
  ).isRequired,
};

export default function CollapsibleTable({
  recipientsData,
  recipientActions,
  onUpdateRecipientClick,
  onDeleteRecipientClick,
  isLoading,
  currencyListData,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalItems
}) {
  return (
    <>
      {isLoading ? (
        <RenderSkeletonRow rowHeadingdata={recipientData} />
      ) : recipientsData.length === 0 ? (
        <EmptyTableComponent
          rowHeadingdata={recipientData}
          tabledata={tabledata}
        />
      ) : (
        <RecipientTableComponent
          rows={recipientsData}
          recipientActions={recipientActions}
          onDeleteRecipientClick={onDeleteRecipientClick}
          onUpdateRecipientClick={onUpdateRecipientClick}
          currencyListData={currencyListData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          totalItems={totalItems}
        />
      )}
    </>
  );
}
