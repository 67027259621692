import React, { useState, useRef, useEffect, useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const OTP = ({
  otpExpirationTimer,
  onResendOTP,
  buttonTitle,
  onVerifyOTP,
  otpLength,
  isVerifyingOTP,
}) => {
  const [otp, setOtp] = useState(Array(otpLength).fill(""));
  const [expirationTime, setExpirationTimer] = useState(
    otpExpirationTimer || 15 * 60
  );

  const otpRefs = useRef([]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      secs < 10 ? "0" + secs : secs
    }`;
  };

  useEffect(() => {
    let expirationInterval;
    if (otpExpirationTimer > 0) {
      expirationInterval = setInterval(() => {
        setExpirationTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(expirationInterval);
    };
  }, [otpExpirationTimer]);

  const handleOtpChange = useCallback(
    (index) => (event) => {
      const value = event.target.value;
      const newOtp = [...otp];

      if (value.length > 1) {
        return; // To avoid inputting more than one character in an individual field
      }

      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next field if input is not empty and it's not the last field
      if (value && index < otpLength - 1) {
        otpRefs.current[index + 1].focus();
      }
    },
    [otp, otpLength]
  );

  const handleKeyDown = (index) => (event) => {
    const key = event.key;

    // Handle backspace to move focus to the previous input
    if (key === "Backspace" && !otp[index] && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text");
    if (pasteData.length !== otpLength) return;

    const newOtp = pasteData.slice(0, otpLength).split("");
    setOtp(newOtp);

    // Move focus to the last filled field
    otpRefs.current[newOtp.length - 1].focus();
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (otp.some((val) => val === "")) return;
    onVerifyOTP(otp.join(""));
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    setOtp(Array(otpLength).fill(""));
    setExpirationTimer(otpExpirationTimer);
    onResendOTP();
  };

  return (
    <>
      <form onSubmit={handleVerifyOTP}>
        <Box display="flex" justifyContent="center" gap="10px" mb={5}>
          {otp.map((value, index) => (
            <TextField
              key={index}
              variant="outlined"
              value={value}
              onChange={handleOtpChange(index)}
              onKeyDown={handleKeyDown(index)} // Handle backspace navigation
              onPaste={handlePaste} // Handle pasting the entire OTP string
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center" },
              }}
              style={{ width: "3em", height: "2em" }}
              inputRef={(el) => (otpRefs.current[index] = el)}
              autoComplete="off"
            />
          ))}
        </Box>
        <Button
          onClick={handleResendOTP}
          size="small"
          disabled={expirationTime > 0}
          style={{
            color: "#8A8887",
            fontFamily: "PPMori-Regular",
          }}
          fullWidth
        >
          {expirationTime > 0
            ? `Resend OTP code in 00:${formatTime(expirationTime)}`
            : <span style={{ color: '#462A9C' }}>Resend OTP code</span>}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            fontFamily: "PPMori-Regular",
            p: 1,
          }}
          disabled={
            isVerifyingOTP || otp.some((val) => val === "")
          }
          endIcon={
            isVerifyingOTP && (
              <CircularProgress
                size={20}
                sx={{
                  color: "white",
                }}
              />
            )
          }
          onClick={handleVerifyOTP}
        >
          {buttonTitle ? buttonTitle : "Verify OTP"}
        </Button>
      </form>
    </>
  );
};

export default OTP;
