import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  TextField,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Phone, WhatsApp, Chat } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const faqs = [
  {
    question: "How many currencies are supported for crypto to fiat conversion?",
    answer:
      "We support 2 digital currencies and up to 20 popular fiat currencies for conversion.",
  },
  {
    question: "How quickly can I convert between crypto and fiat?",
    answer: "Conversion is completed within 10 minutes to 1 hour.",
  },
  {
    question: "How many currencies are available for multi-currency accounts?",
    answer:
      "You can open multi-currency accounts in up to 5 globally popular fiat currencies.",
  },
  {
    question: "Is identity verification required to convert between crypto and fiat?",
    answer: "Yes, completing identity verification is mandatory for all crypto-to-fiat conversions.",
  },
  {
    question: "How long does identity verification take?",
    answer:
      "Identity verification typically takes 6 to 72 hours, depending on your jurisdiction.",
  },
  {
    question: "How fast are payments settled?",
    answer: "Payments are settled within 4 to 72 hours, depending on the jurisdiction.",
  },
  {
    question: "What currencies are supported for payments?",
    answer:
      "You can make payments in over 20 fiat currencies via local payment rails.",
  },
  {
    question: "What payment methods are supported?",
    answer:
      "Payments can be processed via ACH, wire transfers, and international rails for both vendors and employees.",
  },
];

function HelpCenterContainer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [opens, setOpens] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [cardDetails] = useState([{ title: "Opened tickets", amount: "0" }]);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleToggle = (index) => {
    setOpens(open === index ? null : index);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const filteredFAQs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );



  return (
    <Box>
      <Grid container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems="center"
          p={3}
          sx={{
            backgroundColor: "#F6F5F9",
            width: "100%"
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "22px", sm: "26px" },
              fontFamily: "PPMori-Regular",
              color: "#2A2A2A",
            }}
          >
            Help Center
          </Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <Box>
              {/* Chat Support Button */}
              <Button
                startIcon={<Phone />}
                onClick={handleMenuOpen}
                sx={{
                  backgroundColor: "#462A9C",
                  color: "#FFF",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  minWidth: "150px",
                  "&:hover": {
                    backgroundColor: "#462A9C",
                  },
                }}
              >
                Chat Support
              </Button>

              {/* Dropdown Menu */}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    minWidth: "200px",
                  },
                }}
              >
                {/* WhatsApp Support */}
                <MenuItem
                  onClick={() => {
                    window.open("https://api.whatsapp.com/send?phone=919168130591");
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <WhatsApp sx={{ color: "#25D366" }} />
                  </ListItemIcon>
                  <Typography>WhatsApp Support</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (window.fcWidget) {
                      window.fcWidget.init({
                        token: "190b5c4d-25df-4a4f-bd69-a57ec6779217",
                        host: "https://endl.freshchat.com",
                      });
                      window.fcWidget.show();
                      window.fcWidget.open();
                      console.log("Opening FreshChat widget...");
                    } else {
                      console.error("FreshChat widget is not initialized.");
                    }
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Chat sx={{ color: "#462A9C" }} />
                  </ListItemIcon>
                  <Typography>Live Chat</Typography>
                </MenuItem>

              </Menu>
            </Box>
            <Button
              sx={{
                width: "100%",
                backgroundColor: "#462A9C",
                color: "#FFF",
                textTransform: "none",
                fontFamily: "PPMori-Regular",
                "&:hover": {
                  backgroundColor: "#462A9C",
                },
              }}
              onClick={() => {
                window.open("https://endl.freshdesk.com/support/tickets/new");
              }}
            >
              Raise a Ticket
            </Button>
          </Stack>

        </Stack>
        {/* Search Bar */}
        <Box padding={2} style={{ width: "100%" }} display="flex" justifyContent="center" sx={{ width: { xs: "100%" } }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by question..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: { xs: "100%", sm: "50%" } }}
            autoComplete="off"
          />
        </Box>

        {/* FAQ Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "60%" },
              maxWidth: "800px",
              padding: "20px",
              borderRadius: "8px",
              // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h6"
              fontFamily="PPMori-Regular"
              color="#2A2A2A"
              mb={3}
              align="center"
            >
              Frequently Asked Questions
            </Typography>
            {filteredFAQs.length === 0 ? (
              <Typography
                variant="body1"
                fontFamily="PPMori-Regular"
                color="#2A2A2A"
                align="center"
                mt={5}
              >
                Not "{searchTerm}" found
              </Typography>
            ) : (
              <List>
                {filteredFAQs.map((faq, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => handleToggle(index)}
                      aria-expanded={opens === index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px",
                        borderRadius: "10px",
                        backgroundColor: "#FFF",
                        // boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                        marginBottom: "10px",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#F6F5F9",
                        },
                      }}
                    >
                      <ListItemText
                        primary={faq.question}
                        primaryTypographyProps={{
                          variant: "body1",
                          style: { textAlign: "left", fontWeight: 500 },
                        }}
                      />
                      {opens === index ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={opens === index} timeout="auto" unmountOnExit>
                      <Box padding={2}>
                        <Typography variant="body2" color="#6C6C6C">
                          {faq.answer}
                        </Typography>
                      </Box>
                    </Collapse>

                    <Divider sx={{ margin: "0 16px" }} />
                  </React.Fragment>
                ))}
              </List>
            )}
          </Box>
        </Box>


      </Grid>
    </Box>
  );
}

export default HelpCenterContainer;
