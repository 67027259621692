import React from "react";
import { Typography, Box, Button } from "@mui/material";

const LocalBankTransfer = ({ handleGoBack, handleNext, localBankResponse, handleClickDone }) => {
  const instructions = localBankResponse?.deposit_instructions || [];

  const steps = [
    "Access your internet banking or mobile app to initiate the transfer.",
    "Add the Displayed Account as a Beneficiary",
    "Ensure all the following information is correct:",
    "Wait for Beneficiary Activation",
    "Initiate the Transfer",
  ];

  return (
    <Box
      width={{ xs: "90%", sm: "60%", md: "40%" }}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography variant="h6" align="left">
        To add funds to your account, please follow the steps below to transfer
        money from your local bank account:
      </Typography>

      <Box>
        <Typography>
          Log in to Your Bank's Online Portal or Mobile Banking App
        </Typography>
        <ul style={{ padding: 0, listStyleType: "none" }}>
          {steps.map((step, index) => (
            <li key={index} style={{ marginTop: "10px" }}>
              <b> Step {index + 1}: </b> {step}
              {index === 2 && (
                <>
                  {instructions.map((instruction, idx) => (
                    <Box
                      key={idx}
                      mt={3}
                      p={2}
                      border="1px solid #ddd"
                      borderRadius="8px"
                      bgcolor="#f9f9f9"
                    >
                      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                        Payment Method {idx +1}: {instruction?.instruction_type}
                      </Typography>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <tbody>
                          <tr>
                            <td style={{ padding: "6px 0", width: "50%" }}>Account Holder's Name:</td>
                            <td style={{ padding: "6px 0" }}>{instruction?.account_holder_name}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: "6px 0" }}>Bank Name:</td>
                            <td style={{ padding: "6px 0" }}>{instruction?.institution_name}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: "6px 0" }}>Account Number/IBAN:</td>
                            <td style={{ padding: "6px 0" }}>{instruction?.account_number}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: "6px 0" }}>Account Routing Number:</td>
                            <td style={{ padding: "6px 0" }}>{instruction?.account_routing_number}</td>
                          </tr>
                          <tr>
                            <td style={{ padding: "6px 0", width: "50%" }}>Reference ID:</td>
                            <td style={{ padding: "6px 0" }}>{instruction?.memo}</td>
                          </tr>
                        </tbody>
                      </table>
                      <Typography mt={2}>
                        <b>Note:</b> Please include this reference ID in the remarks/invoice number field when initiating the transfer from your bank.
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
              {index === 3 && (
                <Typography component="span" display="block" mt={2}>
                  (Note: Depending on your bank, it might take a few minutes or
                  up to 24 hours to activate the new beneficiary. You'll receive
                  a confirmation once it's done.)
                </Typography>
              )}
              {index === 4 && (
                <Typography component="span" display="block" mt={2}>
                  (Note: After the beneficiary is successfully added, you can
                  transfer the funds.)
                </Typography>
              )}
            </li>
          ))}
        </ul>

        <Typography fontWeight={600} mt={2}>
          Note: Once the transfer is complete, it may take some time to reflect
          in your endl FIAT account.
        </Typography>
      </Box>

      <Box display="flex" gap={1} width="100%">
        {["Go back", "Done"].map((label, index) => (
          <Button
            key={label}
            variant={index === 0 ? "outlined" : "contained"}
            onClick={index === 0 ? handleGoBack : handleClickDone}
            fullWidth
            sx={{
              textTransform: "none",
              backgroundColor: index === 1 ? "#462A9C" : "transparent",
              borderColor: "#462A9C",
              color: index === 1 ? "#ffffff" : "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              py: 1,
            }}
          >
            {label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default LocalBankTransfer;
