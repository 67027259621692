import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import commonHelpers from "../../../utils/common.helpers";

const FormSummerySidebar = ({
  title,
  showSummery,
  showStepOneSummery,
  showStepTwoSummery,
  walletAddress,
  sourceCurrency,
  width,
  selectedPaymentType,
  selectedRecipient,
  selectedPaymentMethod,
  networkValue,
}) => {
  return (
    <Box
      width={width || "100%"}
      display="flex"
      justifyContent="center"
      mb={{ xs: "20px", md: "0px" }}
    >
      <Box width={{ xs: "100%", sm: "50%", lg: "250px" }}>
        <Typography
          variant="h5"
          gutterBottom
          fontSize="22px"
          fontFamily="PPMori-Semi-Bold"
          fontWeight={500}
          textAlign={{ xs: 'center', md: "start" }}
          mb={2}
        >
          {title}
        </Typography>
        {showSummery && (
          <>
                <Typography
                  variant="h6"
                  fontSize="16px"
                  fontWeight="bold"
                  fontFamily="PPMori-Regular"
                  mb={1}
                >
                  Summary
                </Typography>
            <Accordion expanded elevation={0} sx={{ width: "100%" }}>
              <Divider />
              <AccordionDetails sx={{ p:0 }}>
                {showStepOneSummery && (
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" justifyContent="space-between" mt={1}>
                      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
                        Recipient
                      </Typography>
                      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
                        {selectedRecipient?.name || `${selectedRecipient?.firstName} ${selectedRecipient?.lastName}`}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
                        Bank Name
                      </Typography>
                      <Typography fontSize={12} color="rgba(0,0,0,0.70)" fontFamily="PPMori-Regular" width={"50%"}>
                        {commonHelpers.capitalizeFirstLetter(selectedRecipient?.bankName)}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
                        Account Number/IBAN
                      </Typography>
                      <Box display="flex" justifyContent={"flex-start"} width={"50%"}>
                        <MoreHorizIcon fontSize="small" />
                        <Typography fontSize={12} color="rgba(0,0,0,0.70)" fontFamily="PPMori-Regular">
                          {selectedRecipient?.bankAccountNumber?.slice(-4) || selectedRecipient?.iban?.slice(-4)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
                        Payment Rail
                      </Typography>
                        <Typography fontSize={12} color="rgba(0,0,0,0.70)" fontFamily="PPMori-Regular" width={"50%"}>
                          {selectedRecipient?.paymentRail}
                        </Typography>
                    </Box>
                  </Box>
                )}
                {showStepTwoSummery && (
                  <>
                    <Divider sx={{ mb: 1 }} />
                    {selectedPaymentType === "cryptoToFiat" &&
                      selectedPaymentMethod === "CONNECTED_WALLET" && (
                        <ConnectWalletSummery
                          walletAddress={walletAddress}
                          sourceCurrency={sourceCurrency}
                          networkValue={networkValue}
                        />
                      )}
                    {selectedPaymentType === "cryptoToFiat" &&
                      selectedPaymentMethod === "MANUAL_PAYMENT" && (
                        <ManualPaymentSummery sourceCurrency={sourceCurrency} networkValue={networkValue} />
                      )}
                    {selectedPaymentType === "fiatToFiat" && (
                      <FiatPaymentSummery sourceCurrency={sourceCurrency} />
                    )}
                  </>
                )}
              </AccordionDetails>
              <Divider />
            </Accordion>
          </>
        )}
      </Box>
    </Box>
  );
};

const ConnectWalletSummery = ({ walletAddress, sourceCurrency, networkValue }) => (
  <Box display="flex" flexDirection="column" gap={1} mb={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Connected wallet
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Wallet Id
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular" width={"50%"}>
        MetaMask({walletAddress.slice(0, 6)}...{walletAddress.slice(-4)})
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Blockchain network
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        {networkValue}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular" width={"50%"}>
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

const ManualPaymentSummery = ({ sourceCurrency, networkValue }) => (
  <Box display="flex" flexDirection="column" gap={1} mb={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        QR Code payment
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Blockchain network
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular" width={"50%"}>
        {networkValue}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular" width={"50%"}>
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

const FiatPaymentSummery = ({ sourceCurrency }) => (
  <Box display="flex" flexDirection="column" gap={1} mb={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Endl Account
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular" width={"50%"}>
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular" width={"50%"}>
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

export default FormSummerySidebar;
