import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Box,
} from '@mui/material';
import { notify } from '../Notification/Notification';
import { triggerMail } from '../../lib/service/DashboardApi/dashboardApi';
const VerificationModal = ({ open, onClose, statusLink, fetchProfileData, userType }) => {
  const [loading, setLoading] = useState(false);

  const kycText = userType === 'BUSINESS' ? 'KYB' : 'KYC';

  const handleGenerateKYC = async () => {
    setLoading(true);
    try {
      if (statusLink.initiateKycMailSent === false) {
        const response = await triggerMail();
        if(response?.errors?.length){
          response.errors.forEach((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        }else{
          notify.success({
            message: response.message,
            description: response.message,
          })
          await fetchProfileData();
        }
        onClose();
      }
    } catch (e) {
      console.log(e, 'errore')
      e.errors.forEach((err) =>
        notify.error({
          message: err.errMsg,
          description: err.errDesc,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const renderIndividualRequirements = () => (
    <List dense>
      <ListItem>
        <ListItemText
          primary={<Typography sx={{ fontWeight: 'bold' }}>Required Documents</Typography>}

        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          secondary={
            <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '8px', marginBottom: '8px', color: '#000' }}>
              <li>
                <li>Personal and company documents are required.</li>
                <li>Government-issued ID (e.g., Passport, PAN, etc.) is needed.</li>
                <li>
                  US residents must provide a valid 9-digit SSN; otherwise, leave blank.
                </li>
                <li>Please adhere to the following guidelines for submitting your documents.</li>
              </li>
              <Box mt={1}><strong>Passport:</strong> Provide a clear live picture in JPEG format.</Box>
              <Box mt={1}>
                <strong>National ID Card:</strong>
                <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '4px' }}>
                  <li>Attach a live picture in JPEG format.</li>
                  <li>Upload the front side picture in the “ID Front Side” field.</li>
                  <li>Upload the back side picture in the “ID Back Side” field.</li>
                </Box>
              </Box>
              <Box mt={1}>
                <strong>Driver’s License:</strong>
                <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '4px' }}>
                  <li>Attach a live picture in JPEG format.</li>
                  <li>Upload the front side picture in the “Driver License Front Side” field.</li>
                  <li>Upload the back side picture in the “Driver License Back Side” field.</li>
                </Box>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </List>
  );
  const renderBusinessRequirements = () => (
    <List dense>
      <ListItem>
        <ListItemText
          primary={<Typography sx={{ fontWeight: 'bold' }}>Required Documents</Typography>}

        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          secondary={
            <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '8px', marginBottom: '8px', color: '#000' }}>
              <li>
                <li>Personal and company documents are required.</li>
                <li>Government-issued ID (e.g., Passport, PAN, etc.) is needed.</li>
                <li>Company documents like Incorporation Certificate, etc.</li>
                <li>
                  US residents must provide a valid 9-digit SSN; otherwise, leave blank.
                </li>
                <li>Please adhere to the following guidelines for submitting your documents.</li>
              </li>
              <Box mt={1}><strong>Passport:</strong> Provide a clear live picture in JPEG format.</Box>
              <Box mt={1}>
                <strong>National ID Card:</strong>
                <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '4px' }}>
                  <li>Attach a live picture in JPEG format.</li>
                  <li>Upload the front side picture in the “ID Front Side” field.</li>
                  <li>Upload the back side picture in the “ID Back Side” field.</li>
                </Box>
              </Box>
              <Box mt={1}>
                <strong>Driver’s License:</strong>
                <Box component="ul" sx={{ paddingLeft: '20px', marginTop: '4px' }}>
                  <li>Attach a live picture in JPEG format.</li>
                  <li>Upload the front side picture in the “Driver License Front Side” field.</li>
                  <li>Upload the back side picture in the “Driver License Back Side” field.</li>
                </Box>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </List>
  );
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.25rem', color: '#333' }}>
        Complete Your {kycText} in 3 Easy Steps
      </DialogTitle>

      <DialogContent sx={{ padding: '16px 24px' }}>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.6', color: '#555' }}>
          To ensure the security and compliance of our platform, we require you to complete the
          <strong> {kycText} </strong> process. Follow the steps below to submit your documents and verify your identity.
        </Typography>

        <Divider sx={{ marginBottom: '16px' }} />

        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
          Steps to Complete Your {kycText}:
        </Typography>

        <List dense>
          <ListItem>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 1: Check Your Email</Typography>}
              secondary={
                <Typography variant="body2" sx={{ color: '#555' }}>
                  Look for a verification email from <strong>noreply@aerotruth.com</strong>. Follow the instructions to activate your account.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 2: Submit Your Documents</Typography>}
              secondary={
                <Typography variant="body2" sx={{ color: '#555' }}>
                  Fill in your details and complete the {kycText} process by uploading the required documents.
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 3: Wait for Verification</Typography>}
              secondary={
                <Typography variant="body2" sx={{ color: '#555' }}>
                  Once your documents are submitted, our team will review them and notify you via email upon approval.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Divider sx={{ margin: '5px 0' }} />

        {userType === 'INDIVIDUAL' ? renderIndividualRequirements() : renderBusinessRequirements()}

        <Typography variant="body2" sx={{ marginTop: '16px', color: '#777' }}>
          <strong>Note:</strong> If you haven't received the email, please check your spam folder or try again later.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "white",
            color: "#462A9C",
            textTransform: "none",
            fontFamily: "PPMori-Regular",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleGenerateKYC}
          variant="contained"
          sx={{
            backgroundColor: "#462A9C",
            color: "#FFFFFF",
            textTransform: "none",
            fontFamily: "PPMori-Regular",
            "&:hover": {
              backgroundColor: "#462A9C",
            },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} sx={{ color: '#FFF' }} /> : `Start ${kycText}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationModal;
