import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AgreementModal from "./AgreementModal";
import Checkbox from "../Common/Checkbox";

const SignUpCreateAccountPersonalForm = ({
  formData = {},
  formErrors = {},
  handleInput,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [isCertified, setIsCertified] = useState(false);

  const handleOpenAgreementModal = () => {
    setAgreementModalOpen(true);
  };

  const handleCloseAgreementModal = () => {
    setAgreementModalOpen(false);
  };

  const handleCheckboxChange = (e) => {
    handleInput("isCertified", e.target.checked);
  };

  const sanitizeFieldInput = (value) => {
    return value.replace(/[^a-zA-Z ]/g, "");
  };
  const handleFieldSanitizedInput = (field, value) => {
    const sanitizedValue = sanitizeFieldInput(value);
    handleInput(field, sanitizedValue);
  };
  const sanitizeInput = (value) => value.replace(/\s+/g, " ").trim();

  const handleSanitizedInput = (field, value) => {
    const sanitizedValue = sanitizeInput(value);
    handleInput(field, sanitizedValue);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box display={"flex"} width={"100%"} gap={1}>
        {formData?.userType === "INDIVIDUAL" ? (
          <><Box width={"100%"}>
            <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
              First Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              error={!!formErrors.firstName}
              variant="outlined"
              placeholder="Enter your first name"
              name="firstName"
              fullWidth
              value={formData.firstName || ""}
              onChange={(e) => handleFieldSanitizedInput("firstName", e.target.value)} />
            {formErrors.firstName && (
              <Typography fontSize={13} color={"red"}>
                {formErrors.firstName}
              </Typography>
            )}
          </Box><Box width={"100%"}>
              <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
                Last Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                error={!!formErrors.lastName}
                variant="outlined"
                placeholder="Enter your last name"
                name="lastName"
                fullWidth
                value={formData.lastName || ''}
                onChange={(e) => handleFieldSanitizedInput("lastName", e.target.value)} />
              {formErrors.lastName && (
                <Typography fontSize={13} color={"red"}>
                  {formErrors.lastName}
                </Typography>
              )}
            </Box></>) : (

          <Box width={"100%"}>
            <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
              Full Name / Business Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              error={!!formErrors.companyName}
              variant="outlined"
              placeholder="Enter your full name / business name"
              name="companyName"
              fullWidth
              value={formData.companyName || ""}
              id="companyName-input"
              aria-describedby="companyName-error"
              onChange={(e) => handleFieldSanitizedInput("companyName", e.target.value)}
            />
            {formErrors.firstName && (
              <Typography fontSize={13} color={"red"}>
                {formErrors.companyName}
              </Typography>
            )}
          </Box>)}
      </Box>

      <Box>
        <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
          {formData?.userType === "INDIVIDUAL" ? "Email" : "Company Email"}{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          error={!!formErrors.email}
          variant="outlined"
          placeholder={
            formData?.userType === "INDIVIDUAL"
              ? "Enter your email"
              : "Enter your company email"
          }
          name="email"
          fullWidth
          value={formData.email || ""}
          onChange={(e) => handleSanitizedInput("email", e.target.value)}
        />
        {formErrors.email && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.email}
          </Typography>
        )}
      </Box>

      <Box>
        <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
          Password <span style={{ color: "red" }}>*</span>
        </Typography>
        <OutlinedInput
          error={!!formErrors.password}
          variant="outlined"
          name="password"
          placeholder="Enter your password"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={formData.password || ""}
          onChange={(e) => handleSanitizedInput("password", e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formErrors.password && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.password}
          </Typography>
        )}
      </Box>

      {/* Agreement Checkbox */}
      <Box width={"100%"} mt={2}>
        <Checkbox
          checked={formData?.isCertified}
          onChange={handleCheckboxChange}
          label={
            <Typography>
              I agree to the{" "}
              <span
                onClick={handleOpenAgreementModal}
                style={{
                  color: "#0F0F0F",
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderBottom: "1px solid #000",
                  fontSize: '14px'
                }}
              >
                Customer Agreement, Terms & Conditions, and Privacy Policy
              </span>
              .
            </Typography>
          }
        />
      </Box>
      {/* Modal to view Agreements */}
      <AgreementModal
        open={isAgreementModalOpen}
        onClose={handleCloseAgreementModal}
        setIsCertified={setIsCertified}
      />
    </Box>
  );
};

export default SignUpCreateAccountPersonalForm;
