import React, { useState, useContext, useEffect } from "react";
import Web3 from "web3";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as MetaMaskLogo } from "../../Images/MetaMask.svg";
import { ReactComponent as CoinbaseLogo } from "../../Images/coinbase.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InstallIcon from "@mui/icons-material/GetApp";

import "@rainbow-me/rainbowkit/styles.css";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { injected } from "@wagmi/core";

const web3 = new Web3(Web3.givenProvider);

const connectWalletModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
};

const ConnectWalletModal = ({ open, onClose, handleOpenTermsModal, handleOpenPrivacyModal }) => {
  const { connect } = useConnect();
  const props = useAccount();
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [metamaskNotInstalled, setMetamaskNotInstalled] = useState(false);
  const [, setConnectionError] = useState(false);
  const handleConnectMetamaskWallet = async () => {
    if (typeof window.ethereum !== "undefined" && window.ethereum.isMetaMask) {
      setConnectionError(false);
      try {
        connect({ connector: injected() });
      } catch (error) {
        console.log("Connection failed", error);
        setConnectionError(true);
      }
    } else {
      setMetamaskNotInstalled(true);
    }
  };
  const selectWalletAndConnect = (wallet) => {
    setSelectedWallet(wallet);
    if (wallet === "MetaMask") {
      handleConnectMetamaskWallet();
    } else {
      onClose();
    }
    // For other waller we can also do
  };
  const backHandler = () => {
    setMetamaskNotInstalled(false);
    setSelectedWallet(null);
    setConnectionError(false);
  };
  const handleTryAgain = async () => {
    setMetamaskNotInstalled(false);
    setConnectionError(false);
    if (selectedWallet === "MetaMask") {
      handleConnectMetamaskWallet(selectedWallet);
    }
  };

  const handleInstallWallet = () => {
    window.open("https://metamask.io/download.html","_blank");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={connectWalletModalStyle} width={{xs:"90%" , sm:"500px"}} p={{xs:"10px 10px" , sm:"24px"}}>
        {!selectedWallet ? (
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            gap={3}
          >
            <Box position={"absolute"} right={2} top={-6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography textAlign={"center"} variant="h6">
              Connect Wallet
            </Typography>
            <List
              sx={{
                px: 5,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <ListItemButton
                fullWidth
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: 2,
                }}
                onClick={() => !metamaskNotInstalled ? selectWalletAndConnect("MetaMask") : ''}
              >
                <MetaMaskLogo width={30} height={30} />
                <Typography variant="body2" color={"#000"}>
                  MetaMask
                </Typography>
              </ListItemButton>
              {/* <ListItemButton
                fullWidth
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: 2,
                }}
                onClick={() => selectWalletAndConnect("Coinbase")}
              >
                <CoinbaseLogo width={30} height={30} />
                <Typography variant="body2" color={"#000"}>
                  Coinbase
                </Typography>
              </ListItemButton> */}
            </List>
            <Typography
              variant="body1"
              fontSize={15}
              fontWeight={600}
              textAlign={"center"}
              mx={5}
              sx={{ cursor: 'pointer'}}
              onClick={handleInstallWallet}
            >
              I don't have a wallet
            </Typography>
            <Typography
              variant="body1"
              color={"grey"}
              textAlign={"center"}
              fontSize={12}
              mx={5}
            >
              By connecting a wallet, you accept endl's{" "}
              <span
                onClick={handleOpenTermsModal}
                style={{
                  color: "#000",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: 'pointer'
                }}
              >
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={handleOpenPrivacyModal}
                style={{
                  color: "#000",
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: 'pointer'
                }}
              >
                Privacy Policy
              </span>
            </Typography>
          </Box>
        ) : metamaskNotInstalled ? (
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
            px={10}
          >
            <Box position={"absolute"} left={2} top={-6}>
              <IconButton onClick={backHandler}>
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
            </Box>
            <Box position={"absolute"} right={2} top={-6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography textAlign={"center"} variant="h6">
              Connect Wallet
            </Typography>
            <Box
              sx={{
                padding: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MetaMaskLogo width={50} height={50} />
            </Box>
            <Typography variant="h6" textAlign={"center"}>
              Install the MetaMask extension.
            </Typography>
            <Typography textAlign={"center"} fontSize={12} width={200}>
              To connect your MetaMask wallet, install the browser extension.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              style={{
                borderColor: "#462A9C",
                color: "#462A9C",
                textTransform: "none",
                fontFamily: "PPMori-Regular",
                textWrap: "nowrap",
              }}
              startIcon={<InstallIcon />}
              onClick={() =>
                window.open("https://metamask.io/download.html", "_blank")
              }
            >
              Install the metamask extension
            </Button>
          </Box>
        ) : props.isConnecting ? (
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            gap={3}
          >
            <Box position={"absolute"} left={2} top={-6}>
              <IconButton onClick={backHandler}>
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
            </Box>
            <Box position={"absolute"} right={2} top={-6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6" textAlign="center">
              Connect a Wallet
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
            <Typography variant="body1" textAlign="center">
              Requesting connection...
            </Typography>
            <Typography variant="body2" textAlign="center">
              Connect your wallet using the MetaMask browser extension. If it
              doesn't appear, manually open it from the installed extension
              list.
            </Typography>
          </Box>
        ) : props.isConnected ? (
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={3}
          >
            <Box position={"absolute"} right={2} top={-6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ fontSize: "40px", color: "green" }}
              />
            </Box>
            <Typography variant="h6">Connection Successful</Typography>
            <Typography variant="body2" textAlign="center">
              You can now access all features and create endl multi-currency
              accounts.
            </Typography>
            <Box
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#462A9C",
                  color: "#ffffff",
                }}
                onClick={onClose}
              >
                Got it
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"column"}
            gap={3}
          >
            <Box position={"absolute"} right={2} top={-6}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6" textAlign="center">
              Connect a Wallet
            </Typography>
            <Typography variant="h6" textAlign="center">
              Request Cancelled
            </Typography>
            <Typography variant="body1" textAlign="center">
              You cancelled the request. Click the button below to try again.
            </Typography>
            <Box
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
                gap: 1,
              }}
            >
              <Button variant="text" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#462A9C",
                  color: "#ffffff",
                }}
                onClick={handleTryAgain}
              >
                Try again
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const disconnectWalletModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  py: 3,
  px: 5,
};

const DisconnectModal = ({ open, onClose, handleDisconnect }) => {
  const { address } = useAccount();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={disconnectWalletModalStyle}>
        <Box
          position={"relative"}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          <Box position={"absolute"} right={2} top={-6}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h6">Disconnect wallet</Typography>
          <Typography variant="body1" fontSize={14}>
            Are you sure you want to disconnect this wallet ? You might have
            lose some functionality
          </Typography>
          <Box
            fullWidth
            sx={{
              border: "1px solid #ddd",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: 3,
              padding: 2,
              justifyContent: "flex-start",
              textTransform: "none",
            }}
          >
            <MetaMaskLogo width={30} height={30} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              alignItems={"flex-start"}
            >
              <Typography variant="body2" color={"#000"}>
                Connected wallet
              </Typography>
              <Typography variant="body2" fontSize={12} color={"grey"}>
                MetaMask {`(${address?.slice(0, 10)}...${address?.slice(-6)})`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
              gap: 1,
            }}
          >
            <Button variant="text" sx={{ color: "black" }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDisconnect}
            >
              Disconnect wallet
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { ConnectWalletModal, DisconnectModal };
