const commonHelpers = {};

commonHelpers.capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

commonHelpers.formatAddress = (address) => {
    if (!address) return '';
    return address.charAt(0).toUpperCase() + address.slice(1).toLowerCase();
};

commonHelpers.validateFile = ({ file, maxSize, type }) => {
    const fileTypes = {
        image: {
            mimeTypes: ['image/jpeg', 'image/png', 'image/jpg'],
            extensions: ['jpeg', 'png', 'jpg']
        },
        pdf: {
            mimeTypes: ['application/pdf'],
            extensions: ['pdf']
        },
        excel: {
            mimeTypes: [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            ],
            extensions: ['xlsx', 'xls']
        },
        document: {
            mimeTypes: [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.oasis.opendocument.text',
                'application/vnd.oasis.opendocument.presentation',
                'application/vnd.oasis.opendocument.spreadsheet'
            ],
            extensions: ['doc', 'docx', 'odt', 'odp', 'ods']
        }
    };

    const maxFileSize = maxSize || 2 * 1024 * 1024;
    const selectedFileType = fileTypes[type] || {};

    const { mimeTypes = [], extensions = [] } = selectedFileType;

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (file.size > maxFileSize) {
        return {
            isValid: false, message: {
                message: 'File size too large',
                description: 'The selected file is larger than 2MB. Please choose a smaller file.'
            }
        };
    }

    if (!mimeTypes.includes(file.type) || !extensions.includes(fileExtension)) {
        return {
            isValid: false, message: {
                message: 'Invalid file type',
                description: 'The selected file is not a valid file type. Please choose a valid file type.'
            }
        };
    }

    return { isValid: true, message: 'File is valid' };
};


export default commonHelpers;