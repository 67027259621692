import React, { useState } from "react";
import {
  Box,
  RadioGroup,
  Radio,
  styled,
  Typography,
  FormControlLabel,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { changeStatus } from "../../lib/service/accountsApi/accountsApi";
import { notify } from "../Notification/Notification";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  py: 4,
  px: 6,
};
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
    color: "black",
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#462A9C",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#462A9C",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const AccountDeactivateModal = ({
  open,
  onClose,
  accountStatus,
  accountName,
  accountNumber,
  onAccountStatusChange,
}) => {
  const [status, setStatus] = useState(accountStatus);
  const [isLoading, setIsLoading] = useState(false);
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleSubmit = async () => {
    if (accountStatus === status) {
      notify.error({
        message: "Failed to change account status",
        description: "The selected status is same as the current account status",
      });
      return;
    }
    if (!accountNumber) {
      notify.error({
        message:
          status === "CLOSED"
            ? `Failed to inactive your account`
            : `Failed to activate your account`,
        description:
          "We cannot proceed your request to change the status of account. Please try again later",
      });
      return;
    }
    setIsLoading(true);
    try {
      const res = await changeStatus(
        {
          status,
        },
        accountNumber
      );
      notify.success({
        message: `Account status has been set to ${status.toLowerCase()}`,
      });
      onAccountStatusChange(status);
      onClose();
    } catch (error) {
      console.error("Error during opening the account:", error);
      if (error?.errors?.length > 0) {
        error.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message:
            status === "CLOSED"
              ? `Failed to inactive your account`
              : `Failed to activate your account`,
          description:
            "We cannot proceed your request to change the status of account. Please try again later",
        });
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style , width: {xs:"90%" , md:"600px"} , padding : {xs:"20px 20px" , md:"32px 48px"}}}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          position={"relative"}
        >
          <IconButton
            sx={{ position: "absolute", right: 0 }}
            onClick={onClose}
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">Change status</Typography>
          <Typography variant="body1">
            Your current status of bank details for{" "}
            {accountName ? accountName : accountNumber}:{" "}
            {accountStatus === "OPEN" ? (
              <Typography component={"span"} color={"#09B138"}>
                Active
              </Typography>
            ) : (
              <Typography component={"span"} color={"#f44336"}>
                Inactive
              </Typography>
            )}
          </Typography>
          <RadioGroup
            aria-label="status"
            name="status"
            value={status}
            onChange={handleStatusChange}
            style={{
              width: "100%",
            }}
          >
            <FormControlLabel
              value={"OPEN"}
              control={<BpRadio />}
              label={"Active"}
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: 14,
                  fontFamily: "PPMori-Semi-Bold",
                },
              }}
            />
            <FormControlLabel
              value={"CLOSED"}
              control={<BpRadio />}
              label={"Inactive"}
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: 14,
                  fontFamily: "PPMori-Semi-Bold",
                },
              }}
            />
          </RadioGroup>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={3}
          >
            <Button
              variant="text"
              color="primary"
              sx={{
                color: "#462A9C",
                fontSize: "12px",
                textTransform: "none",
                py: 1,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#462A9C",
                color: "#ffffff",
                fontSize: "12px",
                textTransform: "none",
                py: 1,
              }}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? 'Changing status...' : 'Change status'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AccountDeactivateModal;
