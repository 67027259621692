import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Grid,
  Input,
  IconButton,
  useMediaQuery,
  Stack,
  Skeleton,
  TextField,
  Autocomplete,
} from "@mui/material";
import { KeyboardArrowDown, Add, Delete } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { notify } from "../../components/Notification/Notification";
import ChargesModal from "../ModalComponent/CurrencyCalculator/ChargesModal";
import _debounce from "lodash.debounce";
const SendMoneyTransferAmountForm = ({
  sourceCurrency,
  destinationCurrencies,
  receiveCurrency,
  exchangeRate,
  receiveAmount,
  sendAmount,
  chargesFee,
  chargeTotal,
  handleSendAmountChange,
  isFetching,
  total,
  handleGoBack,
  handleNext,
  showReason,
  handleSelectChange,
  transactionPurpose,
  receiveCurrencyValue,
  showAmountError,
  setSelectedFile,
  selectedFile,
  isReasonMandatory,
  selectedRecipient,
  selectedRecipientCountry,
  setSearchTerm,
  searchTerm,
  onReceiveAmountChange,
  topUp = false,
  totalFeesCurrency,
  isQuoteExpired,
  expiresOn,
  isValidateInvoice,
  amountToConvert,
  destinationCurrency,
  sourceCurrencies,
  isFiatToFiat,
}) => {

  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [showCharges, setshowCharges] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [invoiceError, setInvoiceError] = useState(false);

  useEffect(() => {
    if (expiresOn) {
      const expirationDate = new Date(expiresOn);

      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = expirationDate - now;
        if (timeDiff <= 0) {
          setRemainingTime("Expired");
          isQuoteExpired = true;
        } else {
          const minutes = Math.floor(timeDiff / 1000 / 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setRemainingTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        }
      };

      updateRemainingTime(); // Initial call
      const timerInterval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [expiresOn, isQuoteExpired]);

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../Images/flags", false, /\.(png)$/)
  );

  const handleSendAmountInputChange = (event) => {
    const rawValue = event.target.value.replace(/,/g, "");
    const numericRegex = /^\d*\.?\d{0,2}$/;

    if (!numericRegex.test(rawValue)) {
      return;
    }
    console.log(numericRegex, "numericRegexnumericRegex");
    let formattedValue = rawValue;
    if (rawValue) {
      const parts = rawValue?.split(".");
      parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedValue = parts?.join(".");
    }
    setRemainingTime("");
    handleSendAmountChange(formattedValue);
  };

  const handleReceiveAmountInputChange = (event) => {
    const rawValue = event.target.value.replace(/,/g, "");
    const numericRegex = /^\d*\.?\d{0,2}$/;

    if (!numericRegex.test(rawValue)) {
      return;
    }

    let formattedValue = rawValue;
    if (rawValue) {
      const parts = rawValue?.split(".");
      parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedValue = parts?.join(".");
    }

    setRemainingTime("");
    onReceiveAmountChange(formattedValue);
  };

  const handleFileUpload = async (event) => {
    setInvoiceError(false);
    const files = Array.from(event.target.files);

    if (files?.length > 5) {
      notify.error({
        message: "You can only upload up to 5 files",
        description: "",
      });
      event.target.value = null
      return;
    }

    const validFiles = [];
    const filesToAccept = [
      "image/jpeg", // JPG and JPEG
      "image/png",  // PNG
      "application/pdf", // PDF
      "application/msword", // Word (.doc)
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
    ];
    const maxFileSize = 2 * 1024 * 1024;

    for (let file of files) {
      if (!filesToAccept.includes(file?.type)) {
        notify.error({
          message: "Only jpeg, jpg, png, pdf and word files are accepted",
          description: "",
        });
        event.target.value = null
        continue;
      }

      if (file?.size > maxFileSize) {
        notify.error({
          message: `File ${file.name} exceeds the 2MB size limit`,
          description: "",
        });
        event.target.value = null
        continue;
      }

      validFiles.push(file);
    }

    if (validFiles?.length > 0) {
      const newFiles = validFiles.filter(file => {
        const isDuplicate = selectedFile?.some(existingFile => 
          existingFile.name === file.name
        );
        
        if (isDuplicate) {
          notify.error({
            message: `${file.name} already exists`,
            description: "",
          });
          event.target.value = null
          return false;
        }
        return true;
      });

      const totalFiles = (selectedFile?.length || 0) + newFiles.length;
      
      if (newFiles.length && totalFiles <= 5) {
        if (selectedFile?.length) {
          setSelectedFile(prevFiles => [...prevFiles, ...newFiles]);
        } else {
          setSelectedFile(newFiles);
        }
      } else if (totalFiles > 5) {
        notify.error({
          message: "You can only upload up to 5 files",
          description: "",
        });
      }
      event.target.value = null
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileRemove = (index) => {
    setSelectedFile((prevFiles) => prevFiles?.filter((_, i) => i !== index));
  };

  const handleNextPress = () => {
    if (!selectedFile && isValidateInvoice) {
      setInvoiceError(true);
    } else {
      handleNext();
    }
  };

  const tempSourceCurrency = sourceCurrencies?.find(item => (item.code || item.currency) === sourceCurrency)

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={{ xs: 1, lg: 5 }}
      alignItems={isMobile ? "center" : ""}
    >
      {isMobile ? (
        <Stack gap={2} width={{ xs: "100%", sm: "50%", md: "70%" }}>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={"14px"}>You send</Typography>
            <OutlinedInput
              value={sendAmount}
              onChange={handleSendAmountInputChange}
              sx={{
                width: "100%",
                height: "45px",
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "12px 14px",
                  borderRight: "1px solid rgba(0,0,0,0.20)",
                },
              }}
              inputProps={{
                style: {
                  py: 1,
                },
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {
                    isFiatToFiat ? (
                      tempSourceCurrency?.currencySymbol
                    ) : (
                      <img
                        src={images[`${sourceCurrency.toLowerCase()}.png`]}
                        alt={sourceCurrency.toLowerCase()}
                        style={{
                          width: "15px",
                          fontFamily: "PPMori-Regular",
                        }}
                      />
                    )
                  }
                  <Typography
                    variant="body6"
                    fontSize={15}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {sourceCurrency}
                  </Typography>
                </InputAdornment>
              }
              autoComplete="off"
            />
            {showAmountError && (
              <Typography
                variant="body2"
                color="error"
                display="block"
                mt={1}
                sx={{
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: { sm: "12px" },
                  minHeight: "20px",
                }}
              >
                {showAmountError}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={"14px"}>Recipient gets</Typography>
            <OutlinedInput
              value={receiveAmount}
              onChange={handleReceiveAmountInputChange}
              sx={{
                width: "100%",
                height: "45px",
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "12px 14px",
                  borderRight: "1px solid rgba(0,0,0,0.20)",
                },
              }}
              inputProps={{
                style: {
                  py: 1,
                },
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {destinationCurrency}
                  <Typography
                    variant="body6"
                    fontSize={15}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {receiveCurrency}
                  </Typography>
                </InputAdornment>
              }
              autoComplete="off"
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={"14px"}>Transfer purpose</Typography>
            <Autocomplete
              options={transactionPurpose?.codes || []}
              getOptionLabel={(option) => option.category || ""}
              value={
                transactionPurpose?.codes?.find((i) => i.code === showReason) ||
                null
              }
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search a purpose of payment"
                  variant="outlined"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "45px",
                      padding: "0px 10px",
                    },
                    "& input": {
                      fontSize: "14px",
                    },
                  }}
                />
              )}
              sx={{
                width: "100%",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
              }}
              ListboxProps={{
                style: { maxHeight: "150px", fontSize: "14px" },
              }}
              disableClearable
              noOptionsText="No results found"
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            height="45px"
            alignItems="center"
          >
            <Typography fontSize={"14px"}>Fees</Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "PPMori-Semi-Bold",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {chargeTotal} {totalFeesCurrency || " "}
            </Typography>
          </Box>
          {!topUp && (
            <Button
              sx={{
                border: "1px dotted rgba(0,0,0,0.30)",
                color: "rgba(0,0,0,0.70)",
                textTransform: "none",
              }}
              startIcon={<FileUploadOutlinedIcon />}
              onClick={handleButtonClick}
            >
              Upload invoice
              {!selectedFile && isReasonMandatory && (
                <span style={{ color: "red" }}>*</span>
              )}
            </Button>
          )}

          {selectedFile?.length === 0 && isValidateInvoice ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={1}
                mt={2}
              >
                <Typography variant="caption">
                  Note: Maximum 5 documents can be uploaded with 2 MB each
                </Typography>
                <Typography variant="caption">
                  Supported formats : (JPG, JPEG, PDF, Word, PNG)
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                gap={1}
                mt={2}
              >
                <Typography variant="caption">
                  Please upload a document that includes the transaction amount
                  along with the sender and receiver details.
                </Typography>
                <Typography variant="caption">
                  Note: Ensure the document matches the exact amount being sent.
                </Typography>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              gap={1}
              mt={2}
            >
              <Typography variant="caption">
                Note: Maximum 5 documents can be uploaded with 2 MB each
              </Typography>
              <Typography variant="caption">
                Supported formats : (JPG, JPEG, PDF, Word, PNG)
              </Typography>
            </Box>
          )}

          <input
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
            multiple
          />
          {selectedFile?.length > 0 && (
            <Box display="flex" flexDirection="column" gap={1} mt={2}>
              {selectedFile?.map((file, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  key={index}
                  border={"1px solid #efeaea"}
                  width={"100%"}
                  p={1}
                  justifyContent={"space-between"}
                  borderRadius={"8px"}
                >
                  <Typography
                    variant="body2"
                    fontSize="12px"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {file?.name.length > 40
                      ? `${file?.name?.slice(0, 40)}...`
                      : file?.name}
                  </Typography>
                  <IconButton onClick={() => handleFileRemove(index)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Stack>
      ) : (
        <TableContainer
          sx={{
            overflowX: "auto",
          }}
        >
          <Table
            sx={{
              border: "1px solid #D9D7DD",
              borderRadius: "10px",
              minWidth: "800px",
            }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFAFC" }}>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontSize="12px"
                    fontFamily="PPMori-Semi-Bold"
                  >
                    You send
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontSize="12px"
                    fontFamily="PPMori-Semi-Bold"
                  >
                    Recipient gets
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontFamily="PPMori-Semi-Bold"
                    fontSize="12px"
                  >
                    Transfer Purpose
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <Typography
                    variant="h6"
                    fontFamily="PPMori-Semi-Bold"
                    fontSize="12px"
                  >
                    Fee
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box display="flex" flexDirection="column" minHeight="75px">
                    <OutlinedInput
                      sx={{
                        width: "100%",
                        height: "45px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "12px 14px",
                            borderRight: "1px solid rgba(0,0,0,0.20)",
                          },
                      }}
                      onChange={handleSendAmountInputChange}
                      value={sendAmount}
                      inputProps={{
                        style: {
                          py: 1,
                        },
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          {
                            isFiatToFiat ? (
                              tempSourceCurrency?.currencySymbol
                            ) : (
                              <img
                                src={images[`${sourceCurrency.toLowerCase()}.png`]}
                                alt={sourceCurrency.toLowerCase()}
                                style={{
                                  width: "15px",
                                  fontFamily: "PPMori-Regular",
                                }}
                              />
                            )
                          }
                          <Typography
                            variant="body6"
                            fontSize={15}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {sourceCurrency}
                          </Typography>
                        </InputAdornment>
                      }
                      autoComplete="off"
                    />
                    {showAmountError && (
                      <Typography
                        variant="caption"
                        color="error"
                        display="block"
                        fontSize={{ md: "11px" }}
                        mt={1}
                        sx={{
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: { sm: "12px" },
                          minHeight: "20px",
                        }}
                      >
                        {showAmountError}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box display="flex" flexDirection="column" minHeight="75px">
                    <OutlinedInput
                      onChange={handleReceiveAmountInputChange}
                      value={receiveAmount}
                      sx={{
                        width: "100%",
                        height: "45px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "12px 14px",
                            borderRight: "1px solid rgba(0,0,0,0.20)",
                          },
                      }}
                      inputProps={{
                        style: {
                          py: 1,
                        },
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          {destinationCurrency}
                          <Typography
                            variant="body6"
                            fontSize={15}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {receiveCurrency}
                          </Typography>
                        </InputAdornment>
                      }
                      autoComplete="off"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="75px"
                    minWidth="230px"
                  >
                    <Autocomplete
                      options={transactionPurpose?.codes || []}
                      getOptionLabel={(option) => option.category || ""}
                      value={
                        transactionPurpose?.codes?.find(
                          (i) => i.code === showReason
                        ) || null
                      }
                      onChange={handleSelectChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search a purpose of payment"
                          variant="outlined"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "45px",
                              padding: "0px 10px",
                            },
                            "& input": {
                              fontSize: "14px",
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        fontSize: "12px",
                        fontFamily: "PPMori-Regular",
                      }}
                      ListboxProps={{
                        style: { maxHeight: "150px", fontSize: "14px" },
                      }}
                      disableClearable
                      noOptionsText="No results found"
                    />
                  </Box>
                </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    flex: 0,
                    width: "auto",
                    padding: "10px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="75px"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "PPMori-Semi-Bold",
                        whiteSpace: "nowrap",
                        height: "45px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {chargeTotal} {totalFeesCurrency || " "}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              {!topUp ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Button
                      sx={{
                        border: "1px dotted rgba(0,0,0,0.30)",
                        width: "200px",
                        color: "rgba(0,0,0,0.70)",
                        textTransform: "none",
                      }}
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={handleButtonClick}
                    >
                      Upload invoice(s)
                      {!selectedFile && isReasonMandatory && (
                        <Typography color="error" variant="caption">
                          *
                        </Typography>
                      )}
                    </Button>

                    {selectedFile?.length === 0 && isValidateInvoice ? (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                          gap={1}
                          mt={2}
                        >
                          <Typography variant="caption">
                            Note: Maximum 5 documents can be uploaded with 2 MB
                            each
                          </Typography>
                          <Typography variant="caption">
                            Supported formats : (JPG, JPEG, PDF, Word, PNG)
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                          gap={1}
                          mt={2}
                        >
                          <Typography variant="caption">
                            Please upload a document that includes the
                            transaction amount along with the sender and
                            receiver details.
                          </Typography>
                          <Typography variant="caption">
                            Note: Ensure the document matches the exact amount
                            being sent.
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        gap={1}
                        mt={2}
                      >
                        <Typography variant="caption">
                          Note: Maximum 5 documents can be uploaded with 2 MB
                          each
                        </Typography>
                        <Typography variant="caption">
                          Supported formats : (JPG, JPEG, PDF, Word, PNG)
                        </Typography>
                      </Box>
                    )}

                    <input
                      type="file"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      multiple
                    />
                    {selectedFile?.length > 0 && (
                      <Box display="flex" flexDirection="column" gap={1} mt={2}>
                        {selectedFile?.map((file, index) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            gap={1}
                            key={index}
                            border={"1px solid #efeaea"}
                            width={"40%"}
                            p={1}
                            justifyContent={"space-between"}
                            borderRadius={"8px"}
                          >
                            <Typography
                              variant="body2"
                              fontSize="12px"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {file?.name.length > 40
                                ? `${file?.name?.slice(0, 40)}...`
                                : file?.name}
                            </Typography>
                            <IconButton onClick={() => handleFileRemove(index)}>
                              <Delete fontSize="small" />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        display={"flex"}
        width={{ xs: "100%", sm: "50%", md: "50%", lg: "auto" }}
        pb={2}
      >
        {isLargeScreen && <Box width={"100%"}></Box>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box style={{ maxHeight: "100vh" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.875rem",
                      fontFamily: "PPMori-Regular",
                    }}
                  >
                    Exchange rate
                  </Typography>
                  <>
                    <Box display={"flex"} alignItems={"centre"}>
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: "14px",
                          fontFamily: "PPMori-Regular",
                        }}
                      >
                        Total fees
                      </Typography>
                    </Box>
                    {isFetching ? (
                      <Skeleton variant="text" width={80} height={20} />
                    ) : totalFeesCurrency ? (
                      <span
                        onClick={() => setshowCharges(true)}
                        style={{
                          cursor: "pointer",
                          color: "#462A9C",
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Show more
                      </span>
                    ) : null}
                  </>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {isFetching ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {exchangeRate ? (
                        <>
                          1 {sourceCurrency} = {exchangeRate} {receiveCurrency}
                        </>
                      ) : (
                        0
                      )}
                    </Typography>
                  )}
                  {isFetching ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {showAmountError ? 0 : chargeTotal}{" "}
                      {totalFeesCurrency ? totalFeesCurrency : null}
                    </Typography>
                  )}
                </Grid>
                {remainingTime && !isQuoteExpired && (
                  <Typography variant="body2" color="error.main">
                    Quote expires in: {remainingTime}
                  </Typography>
                )}
                {isQuoteExpired && (
                  <Typography
                    color="error"
                    variant="body1"
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    Quote expired! Please generate a new one.
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>
          <Box
            display={"flex"}
            gap={2}
            width={"100%"}
            mt={{ xs: 1, md: 2 }}
            ml={{ xs: "12px", lg: "0px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                borderColor: "#462A9C",
                borderRadius: "8px",
                color: "#462A9C",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                width: "100%",
                py: 1,
              }}
              fullWidth
              onClick={handleGoBack}
            >
              Go back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "12px",
                "&:hover": { backgroundColor: "#462A9C" },
                backgroundColor: "#462A9C",
                color: "#ffffff",
                width: "100%",
              }}
              onClick={topUp ? handleNext : handleNextPress}
              disabled={
                showAmountError ||
                !sendAmount ||
                isFetching ||
                showReason === "" ||
                (!selectedFile && isReasonMandatory) ||
                isQuoteExpired ||
                !totalFeesCurrency ||
                (selectedFile?.length === 0 && isValidateInvoice)
                  ? true
                  : false
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Box>
      {showCharges && (
        <ChargesModal
          chargesFee={chargesFee}
          chargeTotal={chargeTotal}
          showCharges={showCharges}
          onClose={() => setshowCharges(false)}
          sendAmount={sendAmount}
          sendCurrency={sourceCurrency}
          receiveAmount={receiveAmount}
          receiveCurrency={receiveCurrency}
          totalFeesCurrency={totalFeesCurrency}
          exchangeRate={exchangeRate}
          amountToConvert={amountToConvert}
        />
      )}
    </Box>
  );
};

export default SendMoneyTransferAmountForm;
