import React from "react";

const PdfViewer = ({ file }) => {
  const [objectUrl, setObjectUrl] = React.useState("");

  React.useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setObjectUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  const renderFile = (file) => {
    const fileType = file.type;

    if (["image/jpeg", "image/png", "image/jpg"].includes(fileType)) {
      return (
        <img
          src={objectUrl}
          alt={`file`}
          style={{ width: "100%", height: "100%" }}
        />
      );
    }

    if (fileType === "application/pdf") {
      return (
        <embed
          src={objectUrl}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      );
    }

    if (
      fileType === "text/csv" ||
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>Click below to download the file</p>
          <a
            href={objectUrl}
            download={file.name}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              textDecoration: "none",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            Download {file.name}
          </a>
        </div>
      );
    }

    return <p style={{ color: "red" }}>Unsupported file type: {file.name}</p>;
  };

  if (!file) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {renderFile(file)}
    </div>
  );
};

export default PdfViewer;
