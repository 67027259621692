import dayjs from "dayjs";
import commonHelpers from "./common.helpers";

export const getCombinedAddress = (address = {}) => {
  const { addressLine1 = "", addressLine2 = "", city = "", state = "", country = "", postalCode = "" } =
    address;
  const combinedAddress = [
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
  ]
    ?.filter((value) => value) // Filter out null or undefined values
    ?.join(", ");
  if (combinedAddress?.length === 0) {
    return "-";
  }
  return commonHelpers.capitalizeFirstLetter(combinedAddress);
};

export const bankRoutingCodeLabel = {
  // Canada
  40: "Routing number (institution number + transit number)",
  // USA
  236: "Routing number",

  // Singapore
  200: "SWIFT/BIC code",

  // India
  103: "IFSC code",
  // EU region specific
  EU_REGION: "SWIFT/BIC code",
};

export const getRecipientFullName = (firstName, lastName) => {
  return !firstName && firstName === "" && !lastName && lastName === ""
    ? "-"
    : commonHelpers.capitalizeFirstLetter(`${firstName} ${lastName}`);
};

export const relationshipToRecipientMap = {
  SPOUSE: "Spouse",
  EX_SPOUSE: "Ex-Spouse",
  CHILDREN: "Children",
  PARENT: "Parent",
  SIBLING: "Sibling",
  RELATIVE: "Relative",
  SELF: "Self",
  FRIEND: "Friend",
  BUSINESS_PARTNER: "Business Partner",
  CUSTOMER: "Customer",
  EMPLOYEE: "Employee",
  BRANCH_OFFICE: "Branch Office",
  SUBSIDIARY_COMPANY: "Subsidiary Company",
  HOLDING_COMPANY: "Holding Company",
  SUPPLIER: "Supplier",
  CREDITOR: "Creditor",
  DEBTOR: "Debtor",
  FRANCHISEE: "Franchisee",
  NOT_RELATED: "Not Related",
};

export const bankRoutingCodeLabelAndTooltip = {
  // Canada
  40: {
    tooltip:
      "Needs institution number (3 digit number) & transit number (5 digit number) which are unique for the destination bank. Combine them (institution number + transit number) and provide into routing number. If  004 is institution number and 00012 is transit number, put like this 00400012",
    label: "Routing number",
    placeholder: "Enter routing number",
  },

  // USA
  236: {
    label: "Routing number",
    placeholder: "Enter routing number",
    tooltip: "Routing number is 9 character long code.",
  },
  // Singapore
  200: {
    label: "SWIFT/BIC code",
    placeholder: "Enter SWIFT/BIC code",
  },
  // India
  103: {
    label: "IFSC code",
    placeholder: "Enter IFSC code",
  },
  // EU region specific
  EU_REGION: {
    label: "SWIFT/BIC code",
    placeholder: "Enter SWIFT/BIC code",
  },
};

// Note:- Commented for testing purpose
// export const isValidRecipientBankFormData = (bankFormData, bankFormErrors) => {
//   let isValid = true;
//   const errors = { ...bankFormErrors };

//   if (!bankFormData.type || bankFormData.type.trim() === "") {
//     errors.type = "Recipient type is required";
//     isValid = false;
//   }

//   if (!bankFormData.alias || bankFormData.alias.trim() === "") {
//     errors.alias = "Alias (Nickname) is required";
//   }

//   if (!bankFormData.country) {
//     errors.country = "Country is required";
//     isValid = false;
//   }

//   if (!bankFormData.currency) {
//     errors.currency = "Currency is required";
//     isValid = false;
//   }

//   if (!bankFormData.email || bankFormData.email.trim() === "") {
//     errors.email = "Email is required";
//     isValid = false;
//   }

//   if (!bankFormData.firstName || bankFormData.firstName.trim() === "") {
//     errors.firstName = "First name is required";
//     isValid = false;
//   }

//   if (!bankFormData.lastName || bankFormData.lastName.trim() === "") {
//     errors.lastName = "Last name is required";
//     isValid = false;
//   }

//   if (!bankFormData.addressLine1 || bankFormData.addressLine1.trim() === "") {
//     errors.addressLine1 = "Address Line 1 is required";
//     isValid = false;
//   }

//   if (!bankFormData.city || bankFormData.city.trim() === "") {
//     errors.city = "City is required";
//     isValid = false;
//   }

//   if (!bankFormData.state || bankFormData.state.trim() === "") {
//     errors.state = "State is required";
//     isValid = false;
//   } else if (
//     bankFormData.country.id === 236 &&
//     bankFormData.state.trim().length > 2 // USA based validation
//   ) {
//     errors.state =
//       "US state should be in the form of 2 letter abbreviations, For eg. California means CA.";
//     isValid = false;
//   }

//   if (!bankFormData.postalCode || bankFormData.postalCode.trim() === "") {
//     errors.postalCode = "Postal code is required";
//     isValid = false;
//   }

//   if (
//     !bankFormData.dateOfBirth ||
//     bankFormData.dateOfBirth.format("DD-MM-YYYY").trim() === ""
//   ) {
//     errors.dateOfBirth = "Date of birth is required";
//     isValid = false;
//   }

//   if (!bankFormData.phone || bankFormData.phone.trim() === "") {
//     errors.phone = "Phone number is required";
//     isValid = false;
//   }

//   if (
//     !bankFormData.relationshipToCustomer ||
//     bankFormData.relationshipToCustomer.trim() === ""
//   ) {
//     errors.relationshipToCustomer = "Relationship to recipient is required";
//     isValid = false;
//   }

//   if (bankFormData.country) {
//     if (!bankFormData.bankName || bankFormData.bankName.trim() === "") {
//       errors.bankName = "Bank name is required"
//     }
//     switch (bankFormData.country.id) {
//       // India
//       case 103:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.ifsc || bankFormData.ifsc.trim() === "") {
//           errors.ifsc = "IFSC is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;
//       // China, Singapore, Japan, Malaysia, Panama
//       case 45:
//       case 200:
//       case 112:
//       case 134:
//       case 170:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Canada
//       case 40:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.routingNumber ||
//           bankFormData.routingNumber.trim() === ""
//         ) {
//           errors.routingNumber = "Routing Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // US
//       case 236:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.routingNumber ||
//           bankFormData.routingNumber.trim() === ""
//         ) {
//           errors.routingNumber = "Routing Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Bahamas
//       case 16:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Great Britain
//       case 234:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.iban || bankFormData.iban.trim() === "") {
//           errors.iban = "IBAN is required";
//           isValid = false;
//         }
//         if (!bankFormData.sortCode || bankFormData.sortCode.trim() === "") {
//           errors.sortCode = "Sort Code is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // EU region
//       default:
//         if (bankFormData.country.region !== null && bankFormData.country.region === "EU") {
//           if (!bankFormData.iban || bankFormData.iban.trim() === "") {
//             errors.iban = "IBAN is required";
//             isValid = false;
//           }
//           if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//             errors.swiftBic = "Swift BIC is required";
//             isValid = false;
//           }
//         }
//         break;
//     }
//   }
//   console.log(errors);
//   return { isValid, errors }
// };

export const isValidRecipientBankFormData = (bankFormData, bankFormErrors) => {
  let isValid = true;
  const errors = { ...bankFormErrors };

  const regexPatterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    postalCode: /^[A-Za-z0-9\s-]+$/,
    phone: /^\+?[0-9]{7,15}$/,
    ifsc: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    swiftBic: /^[A-Z0-9]{8,11}$/,
    iban: /^[A-Z0-9]{15,34}$/,
    usState: /^[A-Z]{2}$/,
    routingNumber: /^[0-9]{9}$/,
    name: /^[A-Za-z\s]+$/, // Only letters and spaces are allowed
  };

  if (!bankFormData.type || bankFormData.type.trim() === "") {
    errors.type = "Recipient type is required";
    isValid = false;
  }


  if (bankFormData.type) {
    // if(bankFormData.type === "INDIVIDUAL" && ())

    if(bankFormData.type === "INDIVIDUAL") {
      if (!bankFormData.firstName || bankFormData.firstName.trim() === "") {
        errors.firstName = "First name is required";
        isValid = false;
      }

      if (!bankFormData.lastName || bankFormData.lastName.trim() === "") {
        errors.lastName = "Last name is required";
        isValid = false;
      }
    } else {
      if (!bankFormData.fullName || bankFormData.fullName.trim() === "") {
        errors.fullName = "Business name is required";
        isValid = false;
      }
    }

    // switch (bankFormData.type) {
    //   case "INDIVIDUAL":
    //     if (!bankFormData.firstName || bankFormData.firstName.trim() === "") {
    //       errors.firstName = "First name is required";
    //       isValid = false;
    //     }

    //     if (!bankFormData.lastName || bankFormData.lastName.trim() === "") {
    //       errors.lastName = "Last name is required";
    //       isValid = false;
    //     }

    //   case "BUSINESS":
    //     if (!bankFormData.fullName || bankFormData.fullName.trim() === "") {
    //       errors.fullName = "Business name is required";
    //       isValid = false;
    //     }
    // }
  }

  // if (!bankFormData.alias || bankFormData.alias.trim() === "") {
  //   errors.alias = "Alias (Nickname) is required";
  //   isValid = false;
  // }

  if (!bankFormData.country ) {
    errors.country = "Country is required";
    isValid = false;
  }

  if (!bankFormData.currency) {
    errors.currency = "Currency is required";
    isValid = false;
  }

  if (!bankFormData.rail ) {
    errors.rail = "Rail is required";
    isValid = false;
  }

  if (!bankFormData.email || bankFormData.email.trim() === "") {
    errors.email = "Email is required";
    isValid = false;
  }

  // if (
  //   !bankFormData.firstName || bankFormData.firstName.trim() === ""
  // ) {
  //   errors.firstName = "Full name is required";
  //   isValid = false;
  // }

  // if (
  //   !bankFormData.lastName || bankFormData.lastName.trim() === ""
  // ) {
  //   errors.lastName = "Last name is required";
  //   isValid = false;
  // }

  // if (
  //   bankFormData.type === "BUSINESS" && (!bankFormData.fullName || bankFormData.fullName.trim() === "")
  // ) {
  //   errors.fullName = "Business name is required";
  //   isValid = false;
  // }

  if (!bankFormData.addressLine1 || bankFormData.addressLine1.trim() === "") {
    errors.addressLine1 = "Address Line 1 is required";
    isValid = false;
  }

  if (!bankFormData.city || bankFormData.city.trim() === "") {
    errors.city = "City is required";
    isValid = false;
  }

  // if (bankFormData.city && !regexPatterns.name.test(bankFormData.city.trim())) {
  //   errors.city = "City can only contain letters and spaces";
  //   isValid = false;
  // }

  if (!bankFormData.state || bankFormData.state.trim() === "") {
    errors.state = "State is required";
    isValid = false;
  } 
  // else if (
  //   bankFormData.country &&
  //   bankFormData.country.id === 236 &&
  //   !regexPatterns.usState.test(bankFormData.state.trim()) // USA based validation
  // ) {
  //   errors.state =
  //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
  //   isValid = false;
  // }

  // if (
  //   bankFormData.state &&
  //   !regexPatterns.name.test(bankFormData.state.trim())
  // ) {
  //   errors.state = "State can only contain letters and spaces";
  //   isValid = false;
  // }

  if (
    !bankFormData.postalCode || bankFormData.postalCode.trim() === ""
  ) {
    errors.postalCode = "Postal code is required";
    isValid = false;
  }

  if (
    !bankFormData.dateOfBirth ||
    bankFormData.dateOfBirth.format("DD-MM-YYYY").trim() === ""
  ) {
    errors.dateOfBirth = "Date of birth is required";
    isValid = false;
  }

  // if (
  //   bankFormData.dateOfBirth &&
  //   dayjs().diff(dayjs(bankFormData.dateOfBirth), "year") < 18
  // ) {
  //   errors.dateOfBirth = "Recipient cannot be minor.";
  //   isValid = false;
  // }

  if (
    !bankFormData.phone || bankFormData.phone.trim() === ""
  ) {
    errors.phone = "Phone number is required";
    isValid = false;
  }

  if (
    !bankFormData.relationshipToCustomer ||
    bankFormData.relationshipToCustomer.trim() === ""
  ) {
    errors.relationshipToCustomer = "Relationship to recipient is required";
    isValid = false;
  }

  if (bankFormData.currency) {
    if (
      !bankFormData.bankName || bankFormData.bankName.trim() === ""
    ) {
      errors.bankName = "Bank name is required";
      isValid = false;
    }
    switch (bankFormData.currency.id) {
      // India
      case 46:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.ifsc || bankFormData.ifsc.trim() === ""
        ) {
          errors.ifsc = "IFSC is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }

        break;
      // China, Singapore, Japan, Malaysia, Panama
      case 54:
      case 22:
      case 90:
      case 64:
      case 78:
      case 52:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        break;

      // Canada
      case 19:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.bankRoutingNumber || bankFormData.bankRoutingNumber.trim() === "") {
          errors.bankRoutingNumber = "Routing Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === "" 
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        break;

      // US
      case 106:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.bankRoutingNumber || bankFormData.bankRoutingNumber.trim() === "") {
          errors.bankRoutingNumber = "Routing Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === "" 
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        break;

      // Bahamas
      case 7:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === "" 
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        break;

      // Great Britain
      case 105:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.iban || bankFormData.iban.trim() === "") {
          errors.iban = "IBAN number is required";
          isValid = false;
        }
        if (!bankFormData.sortCode || bankFormData.sortCode.trim() === "") {
          errors.sortCode = "Sort Code is required";
          isValid = false;
        }
        if (
          !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
        ) {
          errors.swiftBic = "Swift BIC is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        if (
          !bankFormData.accountAddressLine1 ||
          bankFormData.accountAddressLine1.trim() === ""
        ) {
          errors.accountAddressLine1 = "Address Line 1 is required";
          isValid = false;
        }

        if (!bankFormData.accountCountry ) {
          errors.accountCountry = "Country is required";
          isValid = false;
        }

        if (
          !bankFormData.accountCity ||
          bankFormData.accountCity.trim() === ""
        ) {
          errors.accountCity = "City is required";
          isValid = false;
        }

        // if (
        //   bankFormData.accountCity &&
        //   !regexPatterns.name.test(bankFormData.accountCity.trim())
        // ) {
        //   errors.accountCity = "City can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountState ||
          bankFormData.accountState.trim() === ""
        ) {
          errors.accountState = "State is required";
          isValid = false;
        } 
        // else if (
        //   bankFormData.accountCountry &&
        //   bankFormData.accountCountry.id === 236 &&
        //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
        // ) {
        //   errors.accountState =
        //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
        //   isValid = false;
        // }

        // if (
        //   bankFormData.accountState &&
        //   !regexPatterns.name.test(bankFormData.accountState.trim())
        // ) {
        //   errors.accountState = "State can only contain letters and spaces";
        //   isValid = false;
        // }

        if (
          !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
        ) {
          errors.accountPostalCode = "Postal code is required";
          isValid = false;
        }
        break;

      // United Arab Emirates
      case 115:
          if (!bankFormData.iban || bankFormData.iban.trim() === "") {
            errors.iban = "IBAN number is required";
            isValid = false;
          }
          if (
            !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
          ) {
            errors.swiftBic = "Swift BIC is required";
            isValid = false;
          }
          if (
            !bankFormData.accountAddressLine1 ||
            bankFormData.accountAddressLine1.trim() === ""
          ) {
            errors.accountAddressLine1 = "Address Line 1 is required";
            isValid = false;
          }
  
          if (!bankFormData.accountCountry ) {
            errors.accountCountry = "Country is required";
            isValid = false;
          }
  
          if (
            !bankFormData.accountCity ||
            bankFormData.accountCity.trim() === ""
          ) {
            errors.accountCity = "City is required";
            isValid = false;
          }
  
          // if (
          //   bankFormData.accountCity &&
          //   !regexPatterns.name.test(bankFormData.accountCity.trim())
          // ) {
          //   errors.accountCity = "City can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountState ||
            bankFormData.accountState.trim() === ""
          ) {
            errors.accountState = "State is required";
            isValid = false;
          } 
          // else if (
          //   bankFormData.accountCountry &&
          //   bankFormData.accountCountry.id === 236 &&
          //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
          // ) {
          //   errors.accountState =
          //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
          //   isValid = false;
          // }
  
          // if (
          //   bankFormData.accountState &&
          //   !regexPatterns.name.test(bankFormData.accountState.trim())
          // ) {
          //   errors.accountState = "State can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === "" 
          ) {
            errors.accountPostalCode = "Postal code is required";
            isValid = false;
          }
          if (
            !bankFormData.accountAddressLine1 ||
            bankFormData.accountAddressLine1.trim() === ""
          ) {
            errors.accountAddressLine1 = "Address Line 1 is required";
            isValid = false;
          }
  
          if (!bankFormData.accountCountry ) {
            errors.accountCountry = "Country is required";
            isValid = false;
          }
  
          if (
            !bankFormData.accountCity ||
            bankFormData.accountCity.trim() === ""
          ) {
            errors.accountCity = "City is required";
            isValid = false;
          }
  
          // if (
          //   bankFormData.accountCity &&
          //   !regexPatterns.name.test(bankFormData.accountCity.trim())
          // ) {
          //   errors.accountCity = "City can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountState ||
            bankFormData.accountState.trim() === ""
          ) {
            errors.accountState = "State is required";
            isValid = false;
          } 
          // else if (
          //   bankFormData.accountCountry &&
          //   bankFormData.accountCountry.id === 236 &&
          //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
          // ) {
          //   errors.accountState =
          //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
          //   isValid = false;
          // }
  
          // if (
          //   bankFormData.accountState &&
          //   !regexPatterns.name.test(bankFormData.accountState.trim())
          // ) {
          //   errors.accountState = "State can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
          ) {
            errors.accountPostalCode = "Postal code is required";
            isValid = false;
          }
        break;

      // EU region
      default:
        if (
          bankFormData.currency !== null &&
          bankFormData.currency.code === "EUR"
        ) {
          if (!bankFormData.iban || bankFormData.iban.trim() === "") {
            errors.iban = "IBAN number is required";
            isValid = false;
          }
          if (
            !bankFormData.swiftBic || bankFormData.swiftBic.trim() === ""
          ) {
            errors.swiftBic = "Swift BIC is required";
            isValid = false;
          }
          if (
            !bankFormData.accountAddressLine1 ||
            bankFormData.accountAddressLine1.trim() === ""
          ) {
            errors.accountAddressLine1 = "Address Line 1 is required";
            isValid = false;
          }
  
          if (!bankFormData.accountCountry ) {
            errors.accountCountry = "Country is required";
            isValid = false;
          }
  
          if (
            !bankFormData.accountCity ||
            bankFormData.accountCity.trim() === ""
          ) {
            errors.accountCity = "City is required";
            isValid = false;
          }
  
          // if (
          //   bankFormData.accountCity &&
          //   !regexPatterns.name.test(bankFormData.accountCity.trim())
          // ) {
          //   errors.accountCity = "City can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountState ||
            bankFormData.accountState.trim() === ""
          ) {
            errors.accountState = "State is required";
            isValid = false;
          } 
          // else if (
          //   bankFormData.accountCountry &&
          //   bankFormData.accountCountry.id === 236 &&
          //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
          // ) {
          //   errors.accountState =
          //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
          //   isValid = false;
          // }
  
          // if (
          //   bankFormData.accountState &&
          //   !regexPatterns.name.test(bankFormData.accountState.trim())
          // ) {
          //   errors.accountState = "State can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === "" 
          ) {
            errors.accountPostalCode = "Postal code is required";
            isValid = false;
          }
          if (
            !bankFormData.accountAddressLine1 ||
            bankFormData.accountAddressLine1.trim() === ""
          ) {
            errors.accountAddressLine1 = "Address Line 1 is required";
            isValid = false;
          }
  
          if (!bankFormData.accountCountry ) {
            errors.accountCountry = "Country is required";
            isValid = false;
          }
  
          if (
            !bankFormData.accountCity ||
            bankFormData.accountCity.trim() === ""
          ) {
            errors.accountCity = "City is required";
            isValid = false;
          }
  
          // if (
          //   bankFormData.accountCity &&
          //   !regexPatterns.name.test(bankFormData.accountCity.trim())
          // ) {
          //   errors.accountCity = "City can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountState ||
            bankFormData.accountState.trim() === ""
          ) {
            errors.accountState = "State is required";
            isValid = false;
          } 
          // else if (
          //   bankFormData.accountCountry &&
          //   bankFormData.accountCountry.id === 236 &&
          //   !regexPatterns.usState.test(bankFormData.accountState.trim()) // USA based validation
          // ) {
          //   errors.accountState =
          //     "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
          //   isValid = false;
          // }
  
          // if (
          //   bankFormData.accountState &&
          //   !regexPatterns.name.test(bankFormData.accountState.trim())
          // ) {
          //   errors.accountState = "State can only contain letters and spaces";
          //   isValid = false;
          // }
  
          if (
            !bankFormData.accountPostalCode || bankFormData.accountPostalCode.trim() === ""
          ) {
            errors.accountPostalCode = "Postal code is required";
            isValid = false;
          }
        }
        break;
    }
  }
  console.log(errors);
  return { isValid, errors };
};

export function prepareBankRecipientData(recipientData) {
  let countrySpecificData = {};
  const commonData = {
    bankName: recipientData.bankName,
  };
  switch (recipientData.currency.id) {
    // India
    case 46:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        ifsc: recipientData.ifsc ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    // China, Singapore, Japan, Malaysia, Panama
    case 54:
    case 22:
    case 90:
    case 64:
    case 78:
    case 52:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    // Canada
    case 19:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        bankRoutingNumber: recipientData.bankRoutingNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    // US
    case 106:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        bankRoutingNumber: recipientData.bankRoutingNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    // Bahamas
    case 7:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    // Great Britain
    case 105:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        iban: recipientData.iban ?? null,
        sortCode: recipientData.sortCode ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        },
      };
      break;

    case 115:
      countrySpecificData = {
        iban: recipientData.iban ?? null,
        swiftBic: recipientData.swiftBic ?? null,
        institutionalAddress: {
          addressLine1: recipientData.accountAddressLine1.trim(),
          addressLine2: recipientData.accountAddressLine2?.trim() || null,
          city: recipientData.accountCity?.trim(),
          state: recipientData.accountState?.trim(),
          postalCode: recipientData.accountPostalCode?.trim(),
          country: recipientData.accountCountry?.name.trim(),
          countryId: recipientData.accountCountry?.id,
          region : recipientData?.accountRegion ?? null,
        }
      };
      break;

    // EU region
    default:
      if (
        recipientData.country.region !== null &&
        recipientData.country.region === "EU"
      ) {
        countrySpecificData = {
          iban: recipientData.iban ?? null,
          swiftBic: recipientData.swiftBic ?? null,
          institutionalAddress: {
            addressLine1: recipientData.accountAddressLine1.trim(),
            addressLine2: recipientData.accountAddressLine2?.trim() || null,
            city: recipientData.accountCity?.trim(),
            state: recipientData.accountState?.trim(),
            postalCode: recipientData.accountPostalCode?.trim(),
            country: recipientData.accountCountry?.name.trim(),
            countryId: recipientData.accountCountry?.id,
            region : recipientData?.accountRegion ?? null,
          },
        };
      }
      break;
  }

  return { ...countrySpecificData, ...commonData };
}
