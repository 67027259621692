import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip, Modal, IconButton, Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"; // Icon for currency
import TrendingUpIcon from "@mui/icons-material/TrendingUp"; // Icon for exchange rate
import commonHelpers from "../../../utils/common.helpers";

const ChargesModal = ({ 
  chargesFee, 
  chargeTotal, 
  showCharges, 
  onClose, 
  sendAmount, 
  sendCurrency, 
  receiveAmount, 
  receiveCurrency, 
  totalFeesCurrency, 
  expiresOn,
  exchangeRate, 
  amountToConvert,
}) => {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const checkExpiration = () => {
      const expirationDate = new Date(expiresOn);
      const currentDate = new Date();
      setIsExpired(currentDate > expirationDate);
    };

    // Set interval to check every second
    const interval = setInterval(checkExpiration, 1000);
    
    return () => clearInterval(interval);
  }, [expiresOn]);

  return (
    <div>
      {/* Modal */}
      <Modal open={showCharges} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          p={4}
          bgcolor="background.paper"
          borderRadius={3}
          boxShadow={5}
          maxWidth={450}
          mx="auto"
          my="15vh"
          outline="none"
          sx={{
            "&:focus": {
              outline: "none"
            }
          }}
        >
          <Typography mb={2} style={{ fontWeight: 'bold', color: '#000' }}>
            Currency Calculator Details
          </Typography>

          {isExpired ? (
            <Typography color="error" variant="body1" style={{ fontWeight: 'bold', fontSize: '16px' }}>
              This quote has expired. Please refresh to get a new quote.
            </Typography>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="body1" style={{ fontSize: "16px " , fontWeight: 'bold' }}>
                 {commonHelpers.capitalizeFirstLetter("You Send")} (A)
                </Typography>
                <Typography variant="body1" style={{ fontSize: "16px", color: '#000', fontWeight: 'bold' }}>
                  {sendAmount} {sendCurrency}
                </Typography>
              </Box>
              <Divider />
              {chargesFee?.length ? (
                <Typography variant="h5" mt={1} mb={1} style={{ fontWeight: 'bold', color: '#000', fontSize: "16px" }}>
                  {commonHelpers.capitalizeFirstLetter("Fee Breakdown")}
                </Typography>
              ) : null}
              {chargesFee?.map((fee, index) => (
                <React.Fragment key={index}>
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    <Typography fontSize={16}>
                      {commonHelpers.capitalizeFirstLetter(fee.feeName)}
                    </Typography>
                    <Typography fontSize={16} style={{ color: '#000' }}>
                      {fee.feeAmount} {fee.feeCurrency}
                    </Typography>
                  </Box>
                </React.Fragment>
              ))}
              <Box mb={1} mt={chargesFee?.length ? 0 : 1} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" style={{ fontSize: "16px" , fontWeight: 'bold' }}>
                  {commonHelpers.capitalizeFirstLetter("Total Fees")} (B)
                </Typography>
                <Typography variant="body1" style={{ fontSize: "16px", color: '#000' , fontWeight: 'bold' }}>
                  {chargeTotal} {totalFeesCurrency}
                </Typography>
              </Box>
              <Divider />
              <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" style={{ fontSize: "16px" ,fontWeight: 'bold' }}>
                  {commonHelpers.capitalizeFirstLetter("Exchange Rate")} (C)
                </Typography>
                <Typography variant="body1" style={{ fontSize: "16px", color: '#000' , fontWeight: 'bold' }}>
                  1 {sendCurrency} = {exchangeRate} {receiveCurrency}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box display="flex" mt={1} justifyContent="space-between" alignItems="center">
                <Typography variant="body1" style={{ fontSize: "16px" }}>
                  {commonHelpers.capitalizeFirstLetter("Total Amount We'll Convert")}
                </Typography>
                <Typography variant="body1" style={{ fontSize: "16px", color: '#000' }}>
                  {Number(amountToConvert)?.toLocaleString()} {sendCurrency}
                </Typography>
              </Box>

               <Box display="flex" mt={1} justifyContent="space-between" alignItems="center">
                <Typography variant="body1" style={{ fontSize: "16px" , fontWeight: 'bold'}}>
                  {commonHelpers.capitalizeFirstLetter("Recipient Gets")}  (A-B) * C
                </Typography>
                <Typography variant="body1" style={{ fontSize: "16px", color: '#000', fontWeight: 'bold' }}>
                  {receiveAmount} {receiveCurrency}
                </Typography>
              </Box>

              <Divider sx={{ my: 1 }} />

              {/* How amount is calculated */}
              <Box>
                <Typography variant="body1" style={{ fontWeight: 'bold', color: '#000' }}>
                  {commonHelpers.capitalizeFirstLetter("How Your Amount is Calculated:")}
                </Typography>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                  <TrendingUpIcon fontSize="small" color="action" />
                  <Typography style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}>
                    <span style={{ fontWeight: 'bold' }}>Recipient Gets =</span> (You Send - Total Fees) * Exchange Rate
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ChargesModal;
