import React, { useState, useEffect } from "react";
import {
  Box, Typography, Button, Tabs, Tab,
  IconButton, Collapse, Divider,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";

const ContactSupportForm = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedCountry, setExpandedCountry] = useState(null);

  useEffect(() => {
    return () => {
      if (window.fcWidget) {
        if(window.fcWidget.isOpen()){
          window.fcWidget.close();
        }
      }
    }
  }, [tabIndex]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (window.fcWidget) {
      if (newValue === 1) {
        console.log("Opening FreshChat widget...");
        window.fcWidget.open();
      } else {
        window.fcWidget.close();
      }
    }
  };

  const toggleCountryDropdown = (country) => {
    setExpandedCountry(expandedCountry === country ? null : country);
  };

  const countries = [
    { label: "United States", flag: require("../../Images/flags/usd.png") },
  ];

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} height={"100svh"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{
            backgroundColor: "#F6F5F9",
            width: "100%",
            margin: 0,
          }}
          p={3}
          gap={3}
        >
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/help-center")}>
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
            <Typography
             variant="h3"
             sx={{
               fontSize: { xs: "22px", sm: "26px" },
               fontFamily: "PPMori-Regular",
               color: "#2A2A2A",
             }}
             fontFamily="PPMori-Regular">
              Contact Support
            </Typography>
          </Box>
        </Box>

        {/* Tabs for Call Us and Live Chat */}
        <Box p={3}>
          <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label="Call Us" />
            <Tab label="Live Chat" />
          </Tabs>

          {/* Tab Panel Content */}
          {tabIndex === 0 && (
            <Box display="flex" p={3} justifyContent="space-between" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
              <Box sx={{ width: "100%" }}>
                <Typography variant="h5" fontWeight="bold" mb={1}>Call us from any phone</Typography>
                <Typography mt={1}>When contacting us, please refer to your email id</Typography>
                <Typography fontSize="12px" color="gray" mt={2}>Local or international charges may apply</Typography>
              </Box>

              <Box display="flex" flexDirection="column" width={{ xs: "100%", md: "50%" }}>
                {countries.map((country) => (
                  <Box key={country.label} mb={2} sx={{ borderBottom: "1px solid lightgray" }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1">
                        <img src={country.flag} alt={country.label} /> {country.label}
                      </Typography>
                      <IconButton onClick={() => toggleCountryDropdown(country.label)}>
                        {expandedCountry === country.label ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>

                    <Collapse in={expandedCountry === country.label}>
                      <Divider sx={{ my: 1 }} />
                      <Box display="flex" alignItems="center" mt={1}>
                        <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography fontSize="14px" color="gray">
                          Working hours: Monday-Sunday 1 PM - 10 PM UTC
                        </Typography>
                      </Box>
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {tabIndex === 1 && (
            <Box display="flex" flexDirection="column" p={3} gap={3}>
              <Typography variant="h5">Live Chat</Typography>
              <Box width={{ xs: "100%", md: "600px" }}>
                <Typography fontSize="14px" mb={1}>
                  Got questions or need assistance? Our Live Chat is here for you! Connect with our intelligent chatbot for instant help and support. Fast, convenient, and available 24/7.
                </Typography>
                <Typography fontSize="14px">
                  Feel free to chat with us! Our team is here to assist you.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1} mt={2}>
                <AccessTimeIcon fontSize="small" />
                <Typography fontSize="14px">
                  Working hours: Monday–Sunday, 24 hours
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContactSupportForm;
