const getApiUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "qa":
      return process.env.REACT_APP_QA_API_URL;
    case "staging":
      return process.env.REACT_APP_STAGING_API_URL;
    case "prod":
      return process.env.REACT_APP_PROD_API_URL;
    case "demo":
      return process.env.REACT_APP_DEMO_API_URL;
    default:
      return "https://demo-api.endl.xyz";
  }
};

const apiUrl = getApiUrl();
const environment = process.env.REACT_APP_ENV;

export { apiUrl, environment };

