import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const SidebarSummery = ({
  title,
  showSummery,
  stepOneSummery,
  showStepTwoSummery,
  selectedPaymentMethodType,
  accountDetails,
  walletAddress,
  sourceCurrency,
  width,
  networkValue,
  selectedPaymentMethod
}) => {
  return (
    <Box
      sx={{ display: "flex" }}
      width={width || "100%"}
      display={"flex"}
      justifyContent={{
        xs: "flex-start",
        sm: "center",
        md: "center",
        lg: "center",
      }}
      mb={{ xs: "20px", md: "0px" }}
    >
      <Box width={{ xs: "100%", sm: "50%" }}>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          fontSize="22px"
          fontFamily="PPMori-Semi-Bold"
          fontWeight={500}
          textAlign={{ xs: "center", md: "start" }}
          mb={2}
        >
          {title}
        </Typography>
        {showSummery && (
          <>
            <Typography
              variant="h6"
              fontSize="12px"
              fontWeight="bold"
              fontFamily="PPMori-Regular"
              mb={1}
            >
              Summary
            </Typography>
            <Accordion
              expanded={true}
              elevation={0}
              sx={{ width: { xs: "100%", lg: "250px" } }}
            >
              <Divider  />
              <AccordionDetails sx={{ p: 0 }}>
                {stepOneSummery && (
                  <Box display={"flex"} gap={1} flexDirection={"column"} mt={1}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        fontSize={12}
                        fontFamily="PPMori-Regular"
                        marginBottom="5px"
                      >
                        Recipient
                      </Typography>
                      <Typography fontSize={12} fontFamily="PPMori-Regular">
                        {accountDetails?.name}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          fontSize={12}
                          fontFamily="PPMori-Regular"
                          marginBottom="5px"
                        >
                          Account Name
                        </Typography>
                        <Typography
                          fontSize={12}
                          color="rgba(0,0,0,0.70)"
                          fontFamily="PPMori-Regular"
                        >
                          {accountDetails?.name}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                      >
                        <Typography
                          fontSize={12}
                          fontFamily="PPMori-Regular"
                          marginBottom="5px"
                        >
                          Account Number
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <MoreHorizIcon fontSize="small" />
                          <Typography
                            fontSize={12}
                            color="rgba(0,0,0,0.70)"
                            fontFamily="PPMori-Regular"
                          >
                            {accountDetails.account_number_external?.slice(-4)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {showStepTwoSummery && (
                  <>
                    <Divider sx={{ mb: 1 }} />
                    {selectedPaymentMethodType === "CONNECTED_WALLET" && (
                      <ConnectWalletSummery
                        walletAddress={walletAddress}
                        sourceCurrency={sourceCurrency}
                        networkValue={networkValue}
                      />
                    )}
                    {selectedPaymentMethodType === "MANUAL_PAYMENT" && (
                      <ManualPaymentSummery
                        sourceCurrency={sourceCurrency}
                        networkValue={networkValue}
                      />
                    )}
                  </>
                )}
              </AccordionDetails>
              <Divider />
            </Accordion>
          </>
        )}
      </Box>
    </Box>
  );
};

const ConnectWalletSummery = ({ walletAddress, sourceCurrency, networkValue }) => {
  return (
    <Box display={"flex"} gap={1} flexDirection={"column"} mb={1}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          fontSize={12}
          fontFamily="PPMori-Regular"
          marginBottom="5px"
        >
          Payment method
        </Typography>
        <Typography fontSize={12} fontFamily="PPMori-Regular">
          Connected wallet
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          fontSize={12}
          fontFamily="PPMori-Regular"
          marginBottom="5px"
        >
          Wallet Id
        </Typography>
        <Typography fontSize="12px" fontFamily="PPMori-Regular">
          MetaMask({walletAddress.slice(0, 6)}...
          {walletAddress.slice(-4)})
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          fontSize={12}
          fontFamily="PPMori-Regular"
          marginBottom="5px"
        >
          Blockchain network
        </Typography>
        <Typography fontSize={12} fontFamily="PPMori-Regular">
          {networkValue}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          fontSize={12}
          fontFamily="PPMori-Regular"
          marginBottom="5px"
        >
          Source currency
        </Typography>
        <Typography fontSize="12px" fontFamily="PPMori-Regular">
          {sourceCurrency}
        </Typography>
      </Box>
    </Box>
  );
};

const ManualPaymentSummery = ({ sourceCurrency, networkValue }) => {
  return(
  <Box display={"flex"} gap={1} flexDirection={"column"} mb={1}>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginBottom="5px"
      >
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
      QR Code payment
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginBottom="5px"
      >
        Blockchain network
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {networkValue}
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginBottom="5px"
      >
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
)};

export default SidebarSummery;
