// import sever_ENV from "../../../env"
import directService from "../AppService"
import { checkAuthToken } from "../AuthToken"

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getTranscation = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/txn/tx_metadata/`,
                    method: 'Get',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const transcation = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/txn/52b2dc41-2455-41dd-ae32-4448a5470b24`,
                    method: 'GET',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        } else {

        }
    } catch (error) {
        console.log(error)
    }
}
const getCurrency = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/currency/list`,
                    method: 'GET',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        } else {

        }
    } catch (error) {
        console.log(error)
    }
}

const getQuote = async (data) => {
    try {
      const checkToken = checkAuthToken();
      if (checkToken) {
        const token = getAuthToken();
  
        return await directService({
          url: `api/v1/crypto/get_quote`,
          method: 'POST',
          data: data,
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        });
      } else {
        throw new Error('Authentication token is not valid');
      }
    } catch (error) {
      console.error('Error in getQuote:', error);
      throw error;
    }
  };
  const showWalletAddress = async (data) => {
    try{
        const checkToken = checkAuthToken();
        if (checkToken) {
            const token = getAuthToken();
    
            return await directService({
            url: `api/v1/accounts/deposit_address/USDC`,
            method: 'GET',
            data: data,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
        }else{
            throw new Error('Authentication token is not valid');
        }
    }catch(err){
        console.error('Error in show qr code api',err);
    }
};

  const showQRcode = async (data) => {
        try{
            const checkToken = checkAuthToken();
            if (checkToken) {
                const token = getAuthToken();
        
                return await directService({
                url: `api/v1/accounts/deposit_address/qr/USDC`,
                method: 'GET',
                data: data,
                headers: { Authorization: `Bearer ${token}`},
                responseType: 'blob'
                });
            }else{
                throw new Error('Authentication token is not valid');
            }
        }catch(err){
            console.error('Error in show qr code api',err);
        }
  };

  const generateKYCLink = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/user/kyc/generate_link`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const submitKYC = () => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/user/kyc/submit_confirm`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const changeKYCStatus = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/user/kyc/notif`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${token}` },
                    data: data
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const showTranscationPurpose = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/txn/transfer_purpose_codes`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                    data: data
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const invoiceUpload = async (file) => {
    try {
        const checkToken = checkAuthToken();
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            const formData = new FormData();
            if(file && file?.length){
                file?.forEach(itm => {
                    formData.append('file', itm);
                });
            }else{
                formData.append('file', file);
            }

            return await directService({
                url: `api/v1/txn/invoiceUpload`,
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` },
                data: formData,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

const triggerMail = async () => {
    try {
        const checkToken = checkAuthToken();
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return await directService({
                url: `api/v1/kyc/send/start/mail`,
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` }
            });
        }
    } catch (error) {
        if (error.response) {
            return error.response.data;
        }
        throw error;
    }
};
export { getTranscation, transcation, getCurrency,getQuote, showQRcode, showWalletAddress, generateKYCLink, submitKYC, changeKYCStatus, showTranscationPurpose, invoiceUpload, triggerMail}
