const RouteConstants = {};

RouteConstants.Login = "LOGIN";
RouteConstants.Signup = "SIGNUP";
RouteConstants.Dashboard = "DASHBOARD";
RouteConstants.Accounts = "ACCOUNTS";
RouteConstants.AccountsDetails = "ACCOUNTS_DETAILS";
RouteConstants.Transactions = "TRANSACTIONS";
RouteConstants.Recipients = "RECIPIENTS";
RouteConstants.Profile = "PROFILE";
RouteConstants.HelpCenter = "HELP_CENTER";
RouteConstants.ContactUs = "CONTACT_US";
RouteConstants.LiveChat = "LIVE_CHAT";

export default RouteConstants;