import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DashboardImage from "../../Images/dashboard.png";
import TransactionImage from "../../Images/transaction.png";
import RecipientImage from "../../Images/recipient.png";
import OTP from "../../components/OTP/OTP";
const ForgetPassword = ({
  handleInputChange,
  formData,
  formErrors,
  isLoading,
  currStep,
  resendOTPHandler,
  isSendingOTP,
  handleNext,
  handleGoBack,
}) => {
  const [expirationTime, setExpirationTimer] = useState(3 * 60);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${secs < 10 ? "0" + secs : secs
      }`;
  };
  useEffect(() => {
    if (currStep !== 2) return;
    let expirationInterval;
    if (expirationTime > 0) {
      expirationInterval = setInterval(() => {
        setExpirationTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(expirationInterval);
    };
  }, [currStep]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = useMemo(
    () => [DashboardImage, TransactionImage, RecipientImage],
    []
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  const handleResendOTP = () => {
    setExpirationTimer(2 * 60);
    handleInputChange({ target: { name: "otp", value: "" } });
    resendOTPHandler();
  };

  const isChangePasswordDisabled =
    !formData.password || !formData.password_again || !formData.otp;

  return (
    <Box display={"flex"} width={"100%"} height={"100vh"} overflow={"hidden"}>
      <Box width={"60%"} display={"flex"} flexDirection={"column"} gap={4}>
        <form onSubmit={handleNext}>
          <Box gap={2} display={"flex"} flexDirection={"column"}>
            {currStep === 1 && (
              <>
                <Box>
                  <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
                    Change your password.
                  </Typography>
                </Box>
                <Box>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: "#462A9C",
                      color: "white",
                      textTransform: "none",
                      height: 40,
                      "&:hover": {
                        backgroundColor: "#462A9C",
                      },
                      "&:disabled": {
                        color: "white",
                      },
                    }}
                    disabled={isSendingOTP}
                    onClick={handleNext}
                    endIcon={
                      isSendingOTP && (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: "white",
                          }}
                        />
                      )
                    }
                  >
                    Verify your email
                  </Button>
                </Box>
              </>
            )}

            {currStep === 2 && (
              <>
                <Box>
                  <Typography fontSize={13} color={"rgba(0,0,0,0.8)"} display="flex" alignItems="center">
                    New Password
                    <Typography component="span" color="red" marginLeft="2px">
                      *
                    </Typography>
                  </Typography>
                  <OutlinedInput
                    error={!!formErrors.password}
                    variant="outlined"
                    name="password"
                    placeholder="Enter your password"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleInputChange}
                    sx={{
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "11.5px 14px",
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formErrors.password && (
                    <Typography fontSize={13} color={"red"}>
                      {formErrors.password}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography fontSize={13} color={"rgba(0,0,0,0.8)"} display="flex" alignItems="center">
                    Confirm Password
                    <Typography component="span" color="red" marginLeft="2px">
                      *
                    </Typography>
                  </Typography>
                  <OutlinedInput
                    error={!!formErrors.password_again}
                    variant="outlined"
                    name="password_again"
                    placeholder="Enter confirm password"
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    value={formData.password_again}
                    onChange={handleInputChange}
                    sx={{
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "11.5px 14px",
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword((prev) => !prev)}
                        >
                          {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formErrors.password_again && (
                    <Typography fontSize={13} color={"red"}>
                      {formErrors.password_again}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography fontSize={13} color={"rgba(0,0,0,0.8)"} display="flex" alignItems="center">
                    OTP
                    <Typography component="span" color="red" marginLeft="2px">
                      *
                    </Typography>
                  </Typography>
                  <OutlinedInput
                    error={!!formErrors.otp}
                    variant="outlined"
                    name="otp"
                    placeholder="Enter your OTP"
                    fullWidth
                    value={formData.otp}
                    onChange={handleInputChange}
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "11.5px 14px",
                      },
                    }}
                  />
                  {formErrors.otp && (
                    <Typography fontSize={13} color={"red"}>
                      {formErrors.otp}
                    </Typography>
                  )}
                </Box>
                <Button
                  onClick={handleResendOTP}
                  size="small"
                  disabled={expirationTime > 0}
                  style={{
                    color: "#8A8887",
                    fontFamily: "PPMori-Regular",
                  }}
                  fullWidth
                >
                  {expirationTime > 0
                    ? `Resend OTP code in 00:${formatTime(expirationTime)}`
                    : "Resend OTP code"}
                </Button>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#462A9C",
                    color: "white",
                    textTransform: "none",
                    height: 40,
                    "&:hover": {
                      backgroundColor: "#462A9C",
                    },
                    "&:disabled": {
                        backgroundColor: "#D3D3D3",
                        color: "#A9A9A9", 
                      },
                  }}
                  disabled={isLoading || isChangePasswordDisabled}
                  onClick={handleNext}
                  endIcon={
                    isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "white",
                        }}
                      />
                    )
                  }
                >
                  Change Password
                </Button>
                <Button
                  fullWidth
                  sx={{
                    border: 1,
                    borderColor: "#462A9C",
                    bgcolor: "white",
                    color: "#462A9C",
                    textTransform: "none",
                    height: 40,
                    "&:hover": {
                      bgcolor: "white",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  Go back
                </Button>
              </>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
