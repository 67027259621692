import React from 'react';

const CustomerAgreement = () => {
    return (
        <div className="customer-agreement">
            <h1>Customer Agreement</h1>
            <p>This Customer Agreement ("Agreement") is between you and Zayment Holdings Ltd. DBA “endl” ("endl," "we," or "us"). This Agreement governs your use of the services provided by or on behalf of endl, as described herein (collectively "Services"), including, without limitation, the services and functionality provided through our website, technology software, and APIs (collectively "Platform").</p>
            <p>This Agreement governs your access to and use of the Services. Please read this Agreement carefully as it includes important information about your legal rights. By accessing and/or using the Services, you agree to this Agreement. If you do not understand or agree to this Agreement, do not use the Services.</p>

            <h2>General</h2>

            <h3>Overview of Services</h3>
            <p>Through the Services, you may be eligible to:</p>
            <ul>
                <li>Purchase and Sell Digital Assets (including for Fiat Currency or other Digital Assets)</li>
                <li>Send and receive Digital Assets and Fiat Currency</li>
                <li>Convert Fiat Currency balances to balances in other supported Fiat Currencies</li>
            </ul>
            <p>For supported Digital Assets and Fiat Currencies, visit <a href="https://docs.endl.xyz/guides/assets/" target="_blank">https://docs.endl.xyz/guides/assets/</a>.</p>

            <h3>Third-Party Platforms</h3>
            <p>Our Services may integrate with certain third-party platforms ("Third-Party Platforms"). You agree that such Third-Party Platforms may have additional terms and conditions.</p>

            <h3>Digital Assets</h3>
            <p>You acknowledge and agree that Digital Asset deposits and withdrawals may be delayed due to service downtimes or congestion on the Digital Asset network. Deposits may require a certain number of network confirmations before being settled. We may set withdrawal limits or minimums for Digital Asset transactions from time to time.</p>

            <h3>Custody Providers</h3>
            <p>endl may provide various custody solutions for Digital Assets. These custody solutions may be managed by third-party custodians or service providers. You acknowledge that endl is not responsible for the security or management of Digital Assets held by third-party custodians.</p>

            <h3>No Legal, Investment, Tax or Other Advice</h3>
            <p>endl is not a lawyer, a law firm, investment manager, investment advisor, consultants, consulting firm, accountants, or an accounting firm. Nothing on this Platform is intended to constitute legal, investment, tax, or other advice. The past performance of any product, investment, loan, security, partnership interest, commodity or financial instrument is not a guide to future performance. WITHOUT LIMITING ANYTHING IN THE TERMS, ENDL MAKES NO WARRANTIES AND BEARS NO LIABILITY WITH RESPECT TO ANY OF ITS PRODUCTS OR THE PERFORMANCE THEREOF.</p>
            <h2>Accounts</h2>

            <h3>Eligibility</h3>
            <p>To use the Services, you must also be at least 18 years of age or the legal age of majority in your jurisdiction and comply with the laws of your jurisdiction regarding the use of the Services. Prohibited Person or persons residing in a Restricted Jurisdiction may not be eligible for the Services. A "Prohibited Person" refers to anyone subject to economic or trade sanctions administered by governmental authorities.</p>

            <h3>Creating Your Account/Registration</h3>
            <p>To use the Services, you must complete the registration process and then complete endl’s account opening procedures. First, you must register for an account by verifying your email address. Upon registering your account, an endl Account will be created for you to access your application and other resources. You agree that the registration and other information you provide to open an account is accurate, complete and current. You further agree to promptly update that information to keep it accurate, complete and current. YOU UNDERSTAND THAT A CHANGE IN YOUR STATE OF RESIDENCE MAY IMPACT YOUR ELIGIBILITY TO ACCESS AND USE THE ONLINE PLATFORM, AND HEREBY AGREE TO NOTIFY US IN ADVANCE OF ANY CHANGE IN YOUR STATE OF RESIDENCE. If you register on behalf of a business entity or other organization, you represent and warrant that you have the authority to provide the information required and to bind the organization to the Terms. You acknowledge and agree that endl, in its sole discretion, may suspend or discontinue your, and refuse any and all current and future, access to or use of your endl Account at any time without notice to you.</p>

            <h3>Safeguarding Your Account</h3>
            <p>You are solely responsible for safeguarding your endl Account and maintaining the confidentiality of your password. You must notify us immediately at <a href="mailto:support@endl.app">support@endl.app</a> if you suspect unauthorized use of your account.</p>

            <h3>Verification, Identification, Account Opening, and Regulatory Compliance</h3>
            <p>endl is dedicated to prohibiting and actively preventing money laundering and any activity that facilitates money laundering or the funding of terrorist or criminal activities by observing regional requirements imposed on financial institutions. To that end, endl may collect certain information regarding you and your operations pursuant to your internal procedures/policies/programs. Your access to and use of the services offered by endl is/are subject to compliance with endl’s compliance policies and programs. We also may obtain personal information related to you, from third parties in order to verify your identity, or to prevent fraud. Personal information collected from any source may include, among other things, your name and address, Social Security or taxpayer identification number, and date of birth. You certify under penalty of perjury that the information provided is correct. You hereby authorize us, or a third-party service provider that we designate, to take any measures that we consider necessary to confirm the personal information you provide, verify and authenticate your personal information, and take any action we deem necessary based on the results. You acknowledge that this process may result in a delay in registering your endl Account, and that you may not be authorized to access or use your endl Account until your registration has been successfully completed. As part of our legal compliance program, moreover, we will monitor your endl Account and your use of the Platform, and review your personal information on an ongoing basis, as may be required by law or pursuant to our internal policies and procedures. At any time, we may require you to provide us with additional personal information as a condition to your continued access to and use of your endl Account. During such time, your access to and use of your endl Account may be temporarily restricted.</p>
            <p>endl may require additional verification or documentation as part of the onboarding process or during your use of the Services. You authorize endl to verify your identity and information, which may involve checking commercial databases or credit reports.</p>
            <h3>Wallets</h3>
            <p>To use certain Services, you may need to connect a compatible third-party digital wallet ("Wallet"). You acknowledge that endl is not responsible for the security or management of your Wallet or any assets held therein.</p>

            <h3>Account Limits</h3>
            <p>endl may impose transaction or withdrawal limits or place temporary holds on transactions. These restrictions are implemented to protect the security and integrity of the Services.</p>

            <h3>Account Closure</h3>
            <p>You may close your endl Account at any time. However, closing your account will not affect any obligations or liabilities incurred prior to the closure date. You must cancel all open transactions before closing your account. If no transfer instructions are provided for remaining assets, endl may take any legally permitted action to transfer them to you.</p>

            <h3>Actions Against Your Account</h3>
            <p>endl may comply with legal actions such as subpoenas or garnishments, freezing assets in your account if required by law. Fees or expenses incurred in responding to such actions may be charged to your endl Account.</p>

            <h3>Fees, Subscriptions, and Taxes</h3>

            <h4>Fees</h4>
            <p>You agree to pay all fees associated with the use of the Services. These fees may include transaction fees, service fees, and subscription fees.</p>

            <h4>Taxes</h4>
            <p>You are responsible for reporting and paying any taxes applicable to your transactions via the Services. endl may withhold tax amounts from your transactions if required by law.</p>

            <h4>Subscription Payment</h4>
            <p>If you subscribe to any of our paid Services, you agree to pay the applicable fees and taxes. Subscriptions will automatically renew unless canceled at least 90 days before the renewal date.</p>

            <h4>Subscription Renewals and Cancellation</h4>
            <p>Your subscription will automatically renew unless you cancel 90 days before the renewal date. There are no refunds for partially used subscription periods. You will continue to have access to the Services until the end of the subscription term.</p>

            <h3>Privacy Policy</h3>
            <p>Our Privacy Policy describes how we handle your information. For more information, visit <a href="https://endl.io/legal/privacy-policy" target="_blank">https://endl.io/legal/privacy-policy</a>.</p>
            <h1>Rights and Disclaimers</h1>

            <h2>Rights We Grant You</h2>

            <h3>Right to Use the Services</h3>
            <p>We grant you a personal, non-transferable right to use the Services for personal, non-commercial purposes, as long as you comply with this Agreement.</p>

            <h3>Restrictions on Your Use of the Services</h3>
            <p>You may not copy, distribute, modify, or sublicense the Services without prior written permission. You are also prohibited from engaging in fraudulent or unlawful activities, reverse engineering, or tampering with the Services.</p>

            <h3>Ownership and Content</h3>
            <h4>Ownership of the Services</h4>
            <p>endl owns all rights, title, and interest in the Services, including intellectual property rights. You agree not to take any action inconsistent with endl’s ownership rights.</p>

            <h4>Ownership of Feedback</h4>
            <p>Any feedback or suggestions you provide to endl regarding the Services will be owned by endl. You assign all rights to endl without any compensation or retention of proprietary rights.</p>

            <h2>Third-Party Services</h2>
            <p>The Services and Endl Site may provide access to or link to third-party services (“Third Party Services”). When accessing Third Party Services, you understand that you are at no time transferring your assets to us. We engage Third-Party Services for account creation and custody of VAs and fiat currencies. We provide access to Third Party Services only as a convenience, do not have control over their content, do not warrant or endorse, and are not responsible for the availability, legitimacy of, the content, products, assets, or services on or accessible from those Third-Party Services (including any related websites, resources or links displayed therein), or lack thereof. Third-Party Services are not affiliates of endl or Zayment Holdings Ltd., and operate independently. Third-party Services may provide access to assets which have high risks of illiquidity, devaluation, lockup, or loss.</p>

            <h2>Disclaimers, Limitations of Liability, and Indemnification</h2>

            <h3>Disclaimers</h3>
            <p>The Services are provided "AS IS" and "AS AVAILABLE" without warranties of any kind. endl disclaims all warranties and liabilities to the extent permitted by law.</p>

            <h3>Limitations of Liability</h3>
            <p>endl is not responsible for any damages arising from your use of the Services. In no event shall endl's total liability exceed $100 or the amount you paid in the last six months for the Services, whichever is greater.</p>

            <h3>Indemnification</h3>
            <p>You agree to defend, indemnify, and hold endl and their officers, employees, harmless from any claims, liabilities, or expenses arising out of your violation of this Agreement or misuse of the Services.</p>

            <h2>Risk Disclosures</h2>

            <h3>General Risks</h3>
            <ul>
                <li>Virtual Assets (VAs), including cryptocurrencies, are digital representations of value that are not backed by any government or central bank. They are speculative investments involving a high degree of risk.</li>
                <li>The value of VAs is derived from market demand and supply, making them highly volatile. Prices can fluctuate significantly over short periods, potentially resulting in substantial losses.</li>
                <li>VAs do not have legal tender status and may not be accepted as a means of payment.</li>
                <li>There is a risk of permanent and total loss of value if the market for a particular VA disappears.</li>
            </ul>

            <h3>Regulatory Risks</h3>
            <p>The regulatory environment for VAs is continuously evolving. Changes in laws or regulations at local, state, federal, or international levels may adversely affect the use, transfer, exchange, or value of VAs. Endl is not responsible for any regulations, restrictions, or holds, which are enforced by law.</p>

            <h3>Operational Risks</h3>
            <p>There is no assurance that using our Services will result in profits or meet the User’s investment objectives. Transactions in VAs may be irreversible once recorded on a public ledger. We strongly recommend conducting thorough research before investing in any VA.</p>

            <h3>Cyber Risks</h3>
            <p>VAs may be susceptible to security breaches, cyber-attacks, or other technological difficulties, which could prevent access to your assets. The digital nature of VAs increases the risk of fraud, hacking, and cyber-attacks, which may result in the loss of your assets.</p>

            <h2>Arbitration and Class Action Waiver</h2>

            <h3>Informal Process First</h3>
            <p>Before resorting to formal dispute resolution, you and endl agree to attempt to resolve any dispute amicably or by means of mediation.</p>

            <h3>Arbitration Agreement</h3>
            <p>Any dispute relating to the Services will be resolved through binding arbitration. Both parties waive the right to participate in class action litigation. This does not preclude the right to Class Action Procedures for Arbitration, as specified below.</p>
            <p>Any dispute, controversy, or claim arising out of or relating to this Agreement, including the formation, interpretation, breach or termination thereof, including whether the claims asserted are arbitrable, shall be referred to and determined by arbitration administered by JAMS in accordance with the JAMS International Arbitration Rules in effect as of the day of filing the claim, or as otherwise agreed upon by the parties. Claims shall be heard by a single arbitrator unless the claim amount exceeds $1,500,000.00, in which case the dispute shall be heard by a panel of three arbitrators. The arbitration shall be governed by New York law. The place of arbitration shall be Dubai, U.A.E. The language of the arbitration shall be English. Within 14 days after the commencement of arbitration, each party shall select a person to serve as an arbitrator. In the case of a panel of three arbitrators, the parties shall then select the presiding arbitrator within 14 days after completion of the appointment of the party selections. If any arbitrators are not selected within these time periods, the parties agree to appoint the arbitral tribunal using a rank-and-strike list method. No arbitrator shall have the same nationality as any party to the arbitration. When selecting an arbitrator from neutrals, the parties agree that they will seek to appoint a fair representation of diverse arbitrators (considering gender, ethnicity and sexual orientation), and will request administering institutions to include a fair representation of diverse candidates on their rosters and list of potential arbitrator appointees. Except as may be required by law, neither a party nor an arbitrator may disclose the existence, content, or results of any arbitration hereunder without the prior written consent of both parties.</p>
            <h3>Costs of Arbitration</h3>
            <p>Payment of arbitration costs will be governed by the JAMS International Arbitration Rules.</p>
            <h3>Class Action Procedures for Arbitration</h3>
            <p>If there are 100 or more similar claims filed against endl within 30 days, the administering institutions may administer the claims in batches, with one arbitrator appointed per batch.</p>
            <h3>Severability</h3>
            <p>The parties agree that this arbitration agreement is independent and separable from the other terms of this Agreement. Any decision by the arbitrator or panel of arbitrators(s) or a court of competent jurisdiction that any part of this Agreement, other than this arbitration agreement, is void, voidable, or unenforceable shall not affect the validity or enforceability of this arbitration agreement.</p>
            <h3>Governing Law Venue</h3>
            <p>The validity, interpretation, and performance of this Agreement shall be governed by the laws of the State of New York, without giving effect to the conflicts of laws provisions thereof.</p>
            <h3>Opt-Out Option</h3>
            <p>You may opt out of the arbitration provision within 30 days of agreeing to this Agreement by notifying endl in writing. Written notice for purposes of opting out of the arbitration needs to be submitted to <a href="mailto:support@endl.app">support@endl.app</a> , with the subject line ARBITRATION WAIVER followed by your FIRST NAME and your LAST NAME. FAILURE TO INCLUDE THIS INFORMATION IN THE SUBJECT LINE WITHIN 30 DAYS OF AGREEING TO THIS AGREEMENT WAIVES YOUR RIGHT TO OPT OUT OF ARBITRATION. </p>
            <h3>Class Action Waiver</h3>
            <p>You and endl agree to waive the right to bring class actions or consolidated claims before any court of law and any other forum, except as specified in the Class Action Procedures for Arbitration section of this Agreement.</p>
            <h3>California Residents</h3>
            <p>If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs.</p>
            <h1>Attachments</h1>

            <h2>Attachment A - Additional Terms – United States</h2>
            <p>Additional terms and conditions for users using the US Dollar accounts.</p>
            <h3>Services</h3>
            <h4>General</h4>
            <p>In the United States, endl is not licensed as a money transmitter in the U.S. states the services are provided by a Financial Institution Partner who holds the Money transmitter licenses.</p>

            <h4>endl Services</h4>
            <p>Your endl Account, including any Fiat Currency and Digital Assets held, is not insured by the FDIC or any other governmental agency.</p>

            <h4>Bank Services</h4>
            <p>In states where endl is not licensed as a money transmitter, Fiat Currency may be held in an omnibus custodial account at a Financial Institution Partner. This account does not create a direct relationship between you and the Financial Institution Partner.</p>

            <h3>Fiat Currency Transactions</h3>
            <p>Fiat Currency can be transferred into your endl Account through methods such as ACH or wire transfer. The funds will be available once settled, subject to possible delays.</p>

            <h3>Digital Asset Transactions</h3>
            <p>You may transfer or receive supported Digital Assets into your Digital Asset Account. endl may use shared blockchain addresses to hold Digital Assets on behalf of customers.</p>

            <h3>Transfers</h3>
            <p>You can transfer Fiat Currency or Digital Assets to another account, including non-endl accounts, subject to verification and compliance with the terms of this Agreement.</p>

            <h3>Wire Deposits and Withdrawals</h3>
            <p>Wire transfers are subject to cut-off times, bank holidays, and security procedures. You are responsible for ensuring the accuracy of wire transfer details.</p>

            <h3>ACH Entries</h3>
            <p>ACH transactions may also be subject to cut-off times, bank holidays, and security procedures. You must comply with NACHA Rules for ACH services.</p>

            <h3>Exchange Rates; Transaction Fees</h3>
            <p>endl will determine the exchange rates for supported Fiat Currencies and Digital Assets. Transaction fees may also apply.</p>

            <h3>Instructions</h3>
            <p>Your endl Account is directed by you, and endl acts as your agent to carry out instructions. Once an instruction is submitted, it may not be reversible.</p>

            <h3>Electronic Communications</h3>
            <p>By using the Services, you agree to receive electronic communications from endl.</p>
            <p>Paper Copies</p>
            <p>You may request a paper copy of any Disclosure provided electronically.</p>
            <p>Withdrawing Your Consent</p>
            <p>You may withdraw your consent to receive electronic communications by notifying endl.</p>
            <p>Updating Your Contact Information</p>
            <p>You must promptly notify endl of any changes to your email or mailing address.</p>
            <p>Required Hardware and Software</p>
            <p>To access electronic Disclosures, you need a recent-generation personal computer or mobile device, a web browser, a PDF reader, and a working email address.</p>
            <p>Termination/Changes</p>
            <p>endl reserves the right to terminate or change electronic communication provisions at any time.</p>
            <p>Consumer Rights</p>
            <p>This section applies to you if you are a “consumer” as defined under Regulation E to the Electronic Fund Transfer Act.</p>
            <p>Remittances</p>
            <p>For remittance transfers, you have the right to cancel and obtain a refund within 30 minutes of payment.</p>
            <p>Electronic Fund Transfers</p>
            <p>This section applies to Electronic Fund Transfers via the Services.</p>
            <p>Unauthorized Transfers</p>
            <p>Notify endl immediately if you believe your endl Account credentials have been compromised. Your liability for unauthorized transfers may be limited to $50 if you notify endl within 2 business days.</p>
            <p>Notify endl immediately if you believe your endl Account credentials have been compromised. Your liability for unauthorized transfers may be limited to $50 if you notify endl within 2 business days.</p>
            <p>Report errors within 60 days of the statement showing the error. endl will investigate and correct any errors promptly.</p>

            <h2>Attachment B - Additional and Alternative Terms – European Union</h2>
            <p>Additional terms and conditions for users using the Euro accounts.</p>
            <p>Services</p>
            <p>General</p>
            <p>Endl’s services offered in the EU are offered through a partner who is a licensed virtual currency exchange operator in Lithuania. However, endl is not licensed to provide payment services or issue electronic money under the EU Payment Services Directive or the EU Electronic Money Directive.</p>
            <p>Bank Services</p>
            <p>Fiat Currency will be held in an omnibus custodial account provided by third-party financial institutions.</p>
            <p>Disclaimers</p>
            <p>endl provides the Services on an "AS IS" and "AS AVAILABLE" basis, with no warranties of any kind.</p>
            <p>Governing Law</p>
            <p>This Agreement, including this Europe Addendum, shall be governed by the laws of the State of New York, without giving effect to the conflicts of laws provisions thereof.</p>
            <p>Additional Provisions</p>
            <p>Updates; Monitoring</p>
            <p>endl may update or modify the Services from time to time. Your continued use of the Services is subject to such updates.</p>
            <p>SMS Messaging and Phone Calls</p>
            <p>endl may contact you via phone or text messages, including for marketing purposes. You can opt-out of receiving text messages at any time.</p>
            <p>Termination of License and Your Account</p>
            <p>endl may terminate your account or license to use the Services if you breach this Agreement.</p>
            <p>Injunctive Relief</p>
            <p>endl may seek injunctive relief if you breach this Agreement, causing irreparable harm.</p>
            <p>Unclaimed Property</p>
            <p>endl may report any unclaimed Fiat Currency or Digital Assets in your endl Account to the applicable governmental entity as unclaimed property.</p>
            <p>Export Laws</p>
            <p>You agree to comply with all applicable export laws and regulations when using the Services.</p>
            <p>Force Majeure</p>
            <p>endl is not liable for any failure or delay in fulfilling obligations due to events beyond its control, such as natural disasters, war, or labor strikes.</p>
            <p>Miscellaneous</p>
            <p>If any provision of this Agreement is found to be unlawful or unenforceable, it will be severed, and the remaining provisions will continue in effect. The section headings are for reference only and have no legal effect.</p>
            <h3>How to Contact Us</h3>

            <p>You may contact endl regarding the Services or this Agreement at:</p>
            <ul>
                <li>Zayment Holdings Limited Level 1, IH-00-01-CP-05, Dubai International Financial Centre,</li>
                <li>Dubai, United Arab Emirates.</li>
                <li>info@endl.app</li>
            </ul>
        </div>
    );
};

export default CustomerAgreement;
