import React from "react";
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  styled,
  Select,
  MenuItem
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
    color: "black",
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#462A9C",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#462A9C",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const TopUpPaymentMethodForm = ({
  selectedPaymentMethod,
  handleSelectedPaymentMethodOptionChange,
  handleNext,
  accountDetails,
}) => {
  const countryCode = accountDetails.currency ? accountDetails.currency.toLowerCase() : "default";
  const imagePath = images[`${countryCode}.png`];
  return (
    <Box display={"flex"} alignItems={"center"} gap={2} flexDirection={"column"}>
      <Box display={"flex"} gap={1} flexDirection={"column"} width={"100%"} p={{ xs: "20px" }}>
        <Typography fontWeight={600} textAlign={"left"} width={"100%"}>
          Select payment type
        </Typography>
        <RadioGroup
          aria-label="payment-type"
          name="paymentType"
          value={selectedPaymentMethod}
          style={{ width: "100%" }}
        >
          {[
            { value: "credit/debit", label: "Credit/Debit Card", disabled: true },
            { value: "cryptoCurrency", label: "Crypto Currency" },
            { value: "localBank", label: "Local bank transfer" },
          ].map((option) => (
            <Box
              key={option.value}
              onClick={() =>
                handleSelectedPaymentMethodOptionChange(
                  option.disabled ? selectedPaymentMethod : option.value
                )
              }
              sx={{
                border: "1px solid",
                borderColor:
                  option.value === selectedPaymentMethod ? "#dfdddb" : "#efeeeb",
                backgroundColor: "#fcfbfa",
                mb: 2,
                width: "100%",
                borderRadius: "5px",
                padding: 1,
              }}
            >
              <FormControlLabel
                value={option.value}
                control={<BpRadio />}
                label={option.label}
                disabled={option.disabled}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: 11,
                  },
                  ml: 1,
                }}
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
      {selectedPaymentMethod === "cryptoCurrency" && (
        <Box display={"flex"} gap={1} flexDirection={"column"} width={"100%"} p={{ xs: "10px" }}>
          <Typography
            variant="h6"
            fontWeight={600}
            gutterBottom
            fontFamily="PPMori-Regular"
            textAlign={"left"}
            width={"100%"}
          >
            Recipient details
          </Typography>

          <Typography fontSize={12}>
            Account name
          </Typography>
          <Select
            labelId="recipientName-select-label"
            id="recipientName-selected"
            value={accountDetails?.name || ""}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 350,
                },
              },
            }}
            sx={{
              borderColor: "#dfdddb",
              color: "black",
            }}
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem
              value={accountDetails?.name || ""} key={accountDetails?.id}>
              <Box display={"flex"} gap={"2px"} flexDirection={"column"}>
                <Typography
                  variant="body1"
                  fontSize={14}
                >
                  <img src={imagePath} alt={accountDetails.currency} style={{ width: "15px", marginRight: "8px" }} />
                  {accountDetails?.name}
                </Typography>
              </Box>
            </MenuItem>
          </Select>
          <Typography sx={{ fontSize: "12px" }}>
            Account Number/IBAN
          </Typography>
          <Box
            sx={{
              border: "1px solid #eeecea",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
              marginBottom: "10px",
              borderRadius: "5px",
              width: "100%",
              height: '50px',
              backgroundColor: "#f9f8f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                fontSize={12}
                color="rgba(0,0,0,0.70)">
                {accountDetails?.account_number_external}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          sx={{
            width: "50%",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            textTransform: "none",
            py: 1,
          }}
        >
          Next
        </Button>
      </Box>

    </Box>
  );
};

export default TopUpPaymentMethodForm;
