import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ProtectedRoute, PublicRoute } from "../utils/Routes.helpers";
import Login from "../container/LoginAndSignupContainer/LoginContainer";
import SignUp from "../container/LoginAndSignupContainer/SignUpContainer";
import ForgetPassword from "../container/LoginAndSignupContainer/ForgetPasswordContainer";
import TwoFALogin from "../container/LoginAndSignupContainer/TwoFALoginContainer";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";
import PrivacyModal from "../container/PrivacyComponent/PrivacyComponent";
import TermsOfUseModal from "../container/TermsOfUse/TermsAndCondition";
import NotFoundPage from "../pages/Notfound/NotFoundPage";
import RoutesConfig from "./Routes";
import { useSelector } from "react-redux";

const AppNavigator = () => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.user.isAuthenticated);
  const [isTermsAndConditionModalVisible, setIsTermsAndConditionModalVisible] = React.useState(false);
  const [isPrivacyPolicyModalVisible, setIsPrivacyPolicyModalVisible] = React.useState(false);

  const toggleTermsAndConditionModal = () => {
    setIsTermsAndConditionModalVisible(!isTermsAndConditionModalVisible);
  }

  const togglePrivacyPolicyModalVisible = () => {
    setIsPrivacyPolicyModalVisible(!isPrivacyPolicyModalVisible);
  }

  useEffect(() => {
    if(window.fcWidget){
      if(location.pathname !== '/help-center'){
        window.fcWidget.hide();
      } else {
        window.fcWidget.init({
          token: "190b5c4d-25df-4a4f-bd69-a57ec6779217",
          host: "https://endl.freshchat.com",
        });
        window.fcWidget.hide();
      }
    }
}, [location]);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={<PublicRoute authToken={isLoggedIn} element={Login} />}
        />
        <Route
          path="/signup"
          element={<PublicRoute authToken={isLoggedIn} element={SignUp} />}
        />
        <Route
          path="/2fa-login"
          element={<PublicRoute authToken={isLoggedIn} element={TwoFALogin} />}
        />
        <Route
          path="/forget-password"
          element={
            <PublicRoute authToken={isLoggedIn} element={ForgetPassword} />
          }
        />
        <Route element={<BaseLayout />}>
          {RoutesConfig.map((route, index) => {
            return (
              <Route
                key={`${route.path}_${index}`}
                path={route.path}
                element={
                  <ProtectedRoute
                    authToken={isLoggedIn}
                    element={route.component}
                  />
                }
              />
            );
          })}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <PrivacyModal
        open={isPrivacyPolicyModalVisible}
        handleClose={togglePrivacyPolicyModalVisible}
      />
      <TermsOfUseModal
        open={isTermsAndConditionModalVisible}
        handleClose={toggleTermsAndConditionModal}
      />
    </>
  );
};

export default AppNavigator;
