import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Button,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import RenderSkeletonRow from "../LoaderUI/TableLoader";
import EmptyTableComponent from "./EmptyTableComponent";
import "./TableComponent.css";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";

const TABLE_HEADING_DATA = [
  "Account",
  "Balance",
  // "Currency",
  "Account number",
  // "Recurring payments",
];
const TABLE_DATA = {
  title: "Open account",
  recipient: "No accounts yet",
  description:
    "Open your first endl account",
};

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

const AllAccountsTable = ({ rows, loading }) => {
  return loading ? (
    <RenderSkeletonRow rowHeadingdata={TABLE_HEADING_DATA} />
  ) : !rows || rows.length === 0 ? (
    <EmptyTableComponent
      tabledata={TABLE_DATA}
      rowHeadingdata={TABLE_HEADING_DATA}
    />
  ) : (
    <FilledTableComponent rows={rows} />
  );
};

const FilledTableComponent = ({ rows }) => {
  const navigate = useNavigate();
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "100%", overflowX: "auto", borderRadius: 0, boxShadow: 'none' }}
      
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEADING_DATA.map((heading, index) => (
              <TableCell
                sx={{
                  borderRight:
                    index !== TABLE_HEADING_DATA.length - 1 && "1px solid #ccc",
                  backgroundColor: "#F6F5F9",
                  fontWeight: "bold",
                  fontFamily: "PPMori-Regular",
                  fontSize: "14px",
                }}
                key={`${index}-${heading}`}
              >
                {heading}
              </TableCell>
            ))}
            <TableCell
              sx={{
                fontWeight: "bold",
                backgroundColor: "#F6F5F9",
                fontFamily: "PPMori-Regular",
                fontSize: "14px",
              }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((account) => (
            <TableRow
              sx={{
                cursor: "pointer",
              }}
              onClick={() => navigate(`/account-details/${account.account_number_external}`)} // I think we need ccount id for navigation
            >
              <TableCell className="tablecell">
                <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                  <Box
                    sx={{
                      padding: "10px 12px",
                      border: "1px solid #c3bebe",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        images[`${account?.currency?.toLowerCase()}.png`]
                      }
                    />
                  </Box>
                  <Stack>
                    <Typography variant="caption" gutterBottom sx={{ display: 'block', marginBottom: 0, fontWeight: 600 }}>
                      {account.currency ?? "-"}
                    </Typography>
                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                      {account.name && account.name !== "" ? account.name : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell className="tablecell">{Number(account.balance)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ " " + account.currency}</TableCell>
              {/* <TableCell className="tablecell">{account.currency}</TableCell> */}
              <TableCell className="tablecell">
                {account.account_number.toString().length > 9
                  ? `...
                ${account.account_number.toString().slice(-4)}`
                  : account.account_number}
              </TableCell>
              <TableCell className="tablecell">
                <IconButton aria-label="expand row" size="small">
                    <KeyboardArrowRightIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllAccountsTable;
