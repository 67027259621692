import React, { useMemo, useState, useCallback } from "react";
import SignUp, { Success } from "../../pages/LoginAndSignUp/Signup";
import { register, saveCompanyInfo } from "../../lib/service/authApi/authApi";
import { notify } from "../../components/Notification/Notification";
import { sendOTP, verifyOTP } from "../../lib/service/otpAPi/otpApi";
import { handleErrorResponse } from "../../lib/service/AuthToken";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const INITIAL_FORM_DATA = {
  userType: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  companyName: "",
  phone: "",
  //dob: dayjs("2001-05-26"),
  country: "",
  currency: "",
  logo: "",
  isCertified: ""
};

const INITIAL_FORM_ERRORS = {
  firstName: null,
  lastName: null,
  email: null,
  password: null,
  // companyName: null,
  // phone: null,
  // dob: null,
  // country: null,
  // currency: null,
  logo: null,
};
const SignUpContainer = () => {
  const [formData, setFormData] = useState({ ...INITIAL_FORM_DATA });
  const [formErrors, setFormErrors] = useState({
    ...INITIAL_FORM_ERRORS,
  });
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState();
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [formCurrStep, setCurrFormStep] = useState(0);
  const [updatingCompanyInfo, setUpdatingCompanyInfo] = useState(false);
  const navigate = useNavigate();
  const handleInput = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: null,
    }));
  };
  const handleShowDetailsForm = () => {
    setShowDetailsForm(true);
  };
  const individualFormSteps = useMemo(
    () => ({
      stepsLabel: ["Create account", "Confirm email"],
      stepsHeading: ["Let's get started", "Email verification"],
    }),
    []
  );
  const businessAccountForm = useMemo(
    () => ({
      stepsLabel: ["Create account","Confirm email"],

      stepsHeading: [
        "Let's get started",
        "Let's get started",
        "",
      ],
    }),
    []
  );

  const changeAccountType = (type) => {
    console.log(type);
    setFormData({ ...INITIAL_FORM_DATA, userType: type });
    setFormErrors({ ...INITIAL_FORM_ERRORS });
  };

  // Validation logic can be changed also when the API is implemented
  const isValidInput = () => {
    let isValid = true;
    const errors = { ...formErrors };
  
    // General validation for both BUSINESS and INDIVIDUAL accounts
    if (formCurrStep === 0 && formData.userType === "INDIVIDUAL") {
      if (!formData.firstName || formData.firstName === "") {
        errors.firstName = "First name is required";
        isValid = false;
      }
      if ((!formData.lastName || formData.lastName === "") && formData.userType === "INDIVIDUAL") {
        errors.lastName = "Last name is required";
        isValid = false;
      }
      if (!formData.email || formData.email === "") {
        errors.email =
          formData.userType === "INDIVIDUAL"
            ? "Email is required"
            : "Company email is required";
        isValid = false;
      } 
      // if (
      //   !formData.email.match(
      //    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
      //   )
      // ) {
      //   errors.email = "Please enter a valid email address";
      //   isValid = false;
      // }
  
      if (!formData.password || formData.password === "") {
        errors.password = "Password is required";
        isValid = false;
      }
  
      if (!formData.isCertified) {
        isValid = false;
      }
    }
  
    // Business-specific validation
    if (formData.userType === "BUSINESS") {
      if (formCurrStep === 0) {
        if (!formData.companyName || formData.companyName === "") {
          errors.companyName = "Company name is required";
          isValid = false;
        }
      }
    }
  
    setFormErrors(errors);
    return isValid;
  };
  

  const handleNext = async (e) => {
    e.preventDefault();
    if (!isValidInput()) return;
  
    try {
      setIsCreatingAccount(true);
  
      const { userType, email, firstName, lastName, password, companyName } = formData;
      const requestData =
        userType === "INDIVIDUAL"
          ? { userType, email, firstName, lastName, password, acceptTermsConditionsPolicy: true }
          : { userType, email, companyName, password, acceptTermsConditionsPolicy: true };
      await register(requestData);

      setCurrFormStep((prev) => prev + 1);
      await handleSendOTP();
    } catch (e) {
      if (e?.data?.emailVerified === false) {
        setCurrFormStep((prev) => prev + 1);
        //if (formData.userType === "INDIVIDUAL") {
          setTimeout(async () => await handleSendOTP(), 500);
        //}
      } else if (Array.isArray(e?.errors) && e.errors.length > 0) {
        e.errors.forEach((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to create account.",
          description: "We cannot process the request for account opening. Please try again later.",
        });
      }
    } finally {
      setIsCreatingAccount(false);
    }
  };
  
  
  const handleSendOTP = async () => {
    try {
      await sendOTP({ email: formData.email, phone: null, otpMode: 'EMAIL', operationType: 'REGISTRATION' });
      notify.success({
        message: "OTP has been sent successfully",
      });
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to send the OTP.",
          description:
            "We can not proceed the request for verification of email. Please try again later.",
        });
      }
    }
  };
  const handleVerifyOTP = async (otp) => {
    try {
      setIsVerifyingOTP(true);
      await verifyOTP({
        otp: otp,
        email: formData.email,
        phone: null,
        otpMode: 'EMAIL'
      });
      setEmailVerified(true);
    } catch (e) {
      setEmailVerified(false);

      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to verify the OTP.",
          description:
            "We can not proceed the request for email verification. Please try again later",
        });
      }
    } finally {
      setIsVerifyingOTP(false);
    }
  };
  const handleLogin = () => {
    navigate("/login");
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setCurrFormStep((prev) => prev - 1);
  };

  if (emailVerified) {
    notify.success({
      message: "Account is successfully created.",
    });
    return <Success handleLogin={handleLogin} currentFormStep={formData.userType === "INDIVIDUAL" ? 2 : 3} formSteps={
      formData.userType
        ? formData.userType === "INDIVIDUAL"
          ? individualFormSteps
          : businessAccountForm
        : []
    } showDetailsForm={showDetailsForm}/>;
  }

  return (
    <SignUp
      handleGoBack={handleGoBack}
      formData={formData}
      formErrors={formErrors}
      handleInput={handleInput}
      showDetailsForm={showDetailsForm}
      handleShowDetailsForm={handleShowDetailsForm}
      currentFormStep={formCurrStep}
      handleNext={handleNext}
      changeAccountType={changeAccountType}
      otpLength={6}
      otpExpirationTimer={2 * 60}
      handleResendOTP={handleSendOTP}
      handleVerifyOTP={handleVerifyOTP}
      isVerifyingOTP={isVerifyingOTP}
      isCreatingAccount={isCreatingAccount}
      updatingCompanyInfo={updatingCompanyInfo}
      formSteps={
        formData.userType
          ? formData.userType === "INDIVIDUAL"
            ? individualFormSteps
            : businessAccountForm
          : []
      }
    />
  );
};

export default SignUpContainer;
